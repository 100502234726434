import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ModalImage from "react-modal-image";
import { Button } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
    messageMedia: {
        display: "flex",
        objectFit: "contain",
        width: "100%",
        height: 250,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        padding: "4px 4px 4px 4px",
        margin: "auto",
        border: "1px solid #e2e2e2",
        borderRadius: 8,
        backgroundColor: "#f1f1f1"
    },
    fileNameImage: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        fontWeight: "bold",
        padding: "6px 6px 0px 6px",
        overflowWrap: "break-word"
    },
    downloadMedia: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "inherit",
        flexDirection: "column",
        padding: 4
    }
}));

const ModalImageCors = ({ imageUrl, fromMe, fileName }) => {
    const classes = useStyles();
    const downloadImage = async (imageUrl, fileName) => {
        try {
            // Fazer uma requisição para obter a imagem da URL especificada
            const response = await fetch(imageUrl);

            // Verificar se a resposta é válida
            if (!response.ok) {
                throw new Error(`Erro ao fazer download do arquivo.`);
            }

            // Obter o Blob da resposta
            const blob = await response.blob();

            // Criar uma URL para o Blob
            const url = URL.createObjectURL(blob);

            // Criar um elemento de link
            const link = document.createElement("a");

            // Definir o atributo "href" do link para a URL do Blob
            link.href = url;

            // Definir o atributo "download" do link para o nome do arquivo (opcional)
            link.download = fileName || "image";

            // Adicionar o link ao documento
            document.body.appendChild(link);

            // Clicar no link para iniciar o download
            link.click();

            // Remover o link do documento
            document.body.removeChild(link);

            // Liberar recursos do Blob
            URL.revokeObjectURL(url);
        } catch (error) {
            // Exibir um erro em caso de falha
            toastError(error);
        }
    };

    // Renderizar o componente ModalImage com as propriedades necessárias
    return (
        <>
            <ModalImage className={classes.messageMedia} smallSrcSet={imageUrl} medium={imageUrl} large={imageUrl} alt="image" />
            <div className={classes.fileNameImage}>{fileName}</div>
            {!fromMe && (
                <div className={classes.downloadMedia}>
                    <Button
                        startIcon={<GetApp />}
                        color="primary"
                        variant="outlined"
                        target="_blank"
                        onClick={() => downloadImage(imageUrl, fileName)}>
                        Download
                    </Button>
                </div>
            )}
        </>
    );
};

export default ModalImageCors;
