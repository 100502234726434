import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Checkbox, ListItemText } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import PeopleIcon from "@material-ui/icons/People";

const UserDropdownSelect = ({ userList, selectedUsers = [], onChange }) => {
    const handleChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <div style={{ marginTop: -4 }}>
            <FormControl margin="dense">
                <Select
                    style={{ height: 40 }}
                    multiple
                    displayEmpty
                    variant="outlined"
                    value={selectedUsers}
                    onChange={handleChange}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                    renderValue={() => <PeopleIcon />}>
                    {userList?.length > 0 &&
                        userList.map((queue) => (
                            <MenuItem dense key={queue.id} value={queue.id}>
                                <Checkbox
                                    style={{
                                        color: queue.color
                                    }}
                                    size="small"
                                    color="primary"
                                    checked={selectedUsers.indexOf(queue.id) > -1}
                                />
                                <ListItemText primary={queue.name} />
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default UserDropdownSelect;
