import { i18n } from "../../translate/i18n";
import Swal from "sweetalert2";
import React, { useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";

const ConfirmationModal = ({ title, children, open, onClose, onConfirm }) => {
    const showAlert = async () => {
        const result = await Swal.fire({
            icon: "warning",
            title: typeof title === "string" ? title : <DialogTitle>{title}</DialogTitle>,
            text: children,
            showCancelButton: true,
            confirmButtonColor: "#40b649",
            cancelButtonColor: "#999D9E",
            reverseButtons: true,
            confirmButtonText: i18n.t("confirmationModal.buttons.confirm"),
            cancelButtonText: i18n.t("confirmationModal.buttons.cancel")
        });

        if (result.isConfirmed) {
            onConfirm();
        } else {
            onClose();
        }
    };

    useEffect(() => {
        if (open) {
            showAlert();
        }
    }, [open]);

    return "";
};

export default ConfirmationModal;
