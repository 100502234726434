import React, { createContext, useEffect } from "react";

import { setUser } from "@sentry/react";

import useAuth from "../../hooks/useAuth.js";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const { loading, user, isAuth, handleLogin, handleLogout } = useAuth();

    useEffect(() => {
        if (isAuth) {
            setUser({
                id: user.id,
                email: user.email,
                username: user.name
            });
        } else {
            setUser(null);
        }
    }, [user, isAuth]);

    return <AuthContext.Provider value={{ loading, user, isAuth, handleLogin, handleLogout }}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
