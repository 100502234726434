import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";
import api from "../../services/api";
import Box from "@material-ui/core/Box";
import { Tooltip } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import DoneIcon from "@material-ui/icons/Done";
import Divider from "@material-ui/core/Divider";
import MarkdownWrapper from "../MarkdownWrapper";
import toastError from "../../errors/toastError";
import ListItem from "@material-ui/core/ListItem";
import AndroidIcon from "@material-ui/icons/Android";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { parseISO, format, isSameDay } from "date-fns";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { useHistory, useParams } from "react-router-dom";
import ListItemText from "@material-ui/core/ListItemText";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TicketMessagesDialog from "../TicketMessagesDialog";
import { AuthContext } from "../../context/Auth/AuthContext";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import { green, grey, red, blue } from "@material-ui/core/colors";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import React, { useState, useEffect, useRef, useContext } from "react";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ConfirmationModal from "../../components/ConfirmationModal";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    ticket: {
        position: "relative",
        height: 98,
        paddingHorizontal: 10,
        paddingVertical: 0
    },

    buttonCancel: {
        backgroundColor: "##dce0e1",
        color: "#000"
    },

    pendingTicket: {
        cursor: "unset"
    },

    noTicketsDiv: {
        display: "flex",
        height: "100px",
        margin: 40,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },

    noTicketsText: {
        textAlign: "center",
        color: "rgb(104, 121, 146)",
        fontSize: "14px",
        lineHeight: "1.4"
    },

    noTicketsTitle: {
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "600",
        margin: "0px"
    },

    contactNameWrapper: {
        display: "flex",
        justifyContent: "space-between"
    },

    lastMessageTime: {
        justifySelf: "flex-end",
        textAlign: "right",
        position: "relative",
        top: -23,
        fontSize: 12
    },

    closedBadge: {
        alignSelf: "center",
        justifySelf: "flex-end",
        marginRight: 32,
        marginLeft: "auto"
    },

    contactLastMessage: {
        paddingRight: "50%"
    },

    newMessagesCount: {
        alignSelf: "center",
        marginRight: 0,
        marginLeft: "auto",
        top: -10,
        right: 10
    },

    badgeStyle: {
        color: "white",
        backgroundColor: green[500],
        right: 0,
        top: 10
    },

    acceptButton: {
        position: "absolute",
        right: "108px"
    },

    ticketQueueColor: {
        flex: "none",
        width: "8px",
        height: "100%",
        position: "absolute",
        top: "0%",
        left: "0%"
    },

    divAlignItens: {
        position: "absolute",
        right: 0,
        top: -8,
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "center",
        gap: 5
    },

    ticketInfo: {
        position: "relative",
        top: 0
    },

    ticketInfo1: {
        position: "relative",
        top: 40,
        right: 0
    },
    Radiusdot: {
        "& .MuiBadge-badge": {
            borderRadius: 2,
            position: "inherit",
            height: 16,
            margin: 2,
            padding: 3,
            fontSize: 10
        },
        "& .MuiBadge-anchorOriginTopRightRectangle": {
            transform: "scale(1) translate(0%, -40%)"
        }
    }
}));

const TicketListItemCustom = ({ ticket = {}, setUpdate }) => {
    const classes = useStyles();
    const history = useHistory();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmaDelete, setconfirmaDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ticketUser, setTicketUser] = useState(null);
    const [whatsAppName, setWhatsAppName] = useState(null);

    const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);
    const { ticketId } = useParams();
    const isMounted = useRef(true);
    const { setCurrentTicket } = useContext(TicketsContext);
    const { user } = useContext(AuthContext);
    const { profile } = user;

    useEffect(() => {
        if (ticket.user?.name) {
            setTicketUser(ticket.user.name);
        }

        if (ticket.whatsapp?.name) {
            setWhatsAppName(ticket.whatsapp.name);
        }

        return () => {
            isMounted.current = false;
        };
    }, [ticket.user, ticket.whatsapp]);

    const handleCloseTicket = async (id) => {
        setLoading(true);
        try {
            await api.put(`/tickets/${id}`, {
                action: "close",
                status: "closed",
                justClose: true,
                userId: user?.id
            });
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
        if (isMounted.current) {
            setLoading(false);
        }

        history.push(`/tickets/`);
        setUpdate(Math.random());

        setLoading(false);
    };

    const handleAcepptTicket = async (id) => {
        setLoading(true);
        try {
            await api.put(`/tickets/${id}`, {
                action: "accept",
                status: "open",
                userId: user?.id
            });
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
        if (isMounted.current) {
            setLoading(false);
        }

        history.push(`/tickets/${ticket.uuid}`);
        setUpdate(Math.random());
        setLoading(false);
    };

    const handleSelectTicket = (ticket) => {
        const code = uuidv4();
        const { id, uuid } = ticket;
        setCurrentTicket({ id, uuid, code });
    };

    const renderTicketInfo = () => {
        return (
            <React.Fragment>
                <ConfirmationModal
                    title={"Finalizar ticket"}
                    children={"Tem certeza que deseja finalizar este ticket?"}
                    open={confirmationOpen}
                    onClose={() => setConfirmationOpen(false)}
                    onConfirm={() => handleCloseTicket(ticket.id)}></ConfirmationModal>
                {ticketUser && (
                    <Badge
                        className={classes.Radiusdot}
                        badgeContent={`${ticketUser}`}
                        //color="primary"
                        style={{
                            backgroundColor: "#3498db",
                            height: 18,
                            padding: 5,
                            position: "inherit",
                            borderRadius: 7,
                            color: "#fff",
                            top: -6,
                            marginRight: 3
                        }}
                    />
                )}
                {whatsAppName && (
                    <Badge
                        className={classes.Radiusdot}
                        badgeContent={`${whatsAppName}`}
                        style={{
                            backgroundColor: "#7d79f2",
                            height: 18,
                            padding: 5,
                            position: "inherit",
                            borderRadius: 7,
                            color: "white",
                            top: -6,
                            marginRight: 3
                        }}
                    />
                )}

                {ticket.queue?.name !== null && (
                    <Badge
                        className={classes.Radiusdot}
                        style={{
                            //backgroundColor: ticket.queue?.color,
                            backgroundColor: ticket.queue?.color || "#7C7C7C",
                            height: 18,
                            padding: 5,
                            paddingHorizontal: 12,
                            position: "inherit",
                            borderRadius: 7,
                            color: "white",
                            top: -6,
                            marginRight: 2
                        }}
                        badgeContent={ticket.queue?.name || "Sem fila"}
                    />
                )}
                <div className={classes.divAlignItens}>
                    {ticket.status === "pending" && (
                        <Tooltip title="Fechar Conversa">
                            <ClearOutlinedIcon
                                onClick={() => handleCloseTicket(ticket.id)}
                                fontSize="small"
                                style={{
                                    color: "#fff",
                                    backgroundColor: red[700],
                                    cursor: "pointer",
                                    height: 23,
                                    width: 23,
                                    fontSize: 12,
                                    borderRadius: 50,
                                    padding: 2
                                }}
                            />
                        </Tooltip>
                    )}
                    {ticket.chatbot && (
                        <Tooltip title="Chatbot">
                            <AndroidIcon fontSize="small" style={{ color: grey[700], marginRight: 5 }} />
                        </Tooltip>
                    )}

                    {ticket.status === "open" && (
                        <Tooltip title="Fechar Conversa">
                            <ClearOutlinedIcon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setConfirmationOpen(true);
                                }}
                                fontSize="small"
                                style={{
                                    color: "#fff",
                                    backgroundColor: red[700],
                                    cursor: "pointer",
                                    height: 23,
                                    width: 23,
                                    fontSize: 12,
                                    borderRadius: 50,
                                    cursor: "pointer",
                                    padding: 2
                                }}
                            />
                        </Tooltip>
                    )}

                    {ticket.status === "pending" && (
                        <Tooltip title="Aceitar Conversa">
                            <DoneIcon
                                onClick={() => handleAcepptTicket(ticket.id)}
                                fontSize="small"
                                style={{
                                    color: "#fff",
                                    backgroundColor: green[700],
                                    cursor: "pointer",
                                    padding: 2,
                                    height: 23,
                                    width: 23,
                                    fontSize: 12,
                                    borderRadius: 50
                                }}
                            />
                        </Tooltip>
                    )}
                    {profile === "admin" && (
                        <Tooltip title="Espiar Conversa">
                            <VisibilityIcon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOpenTicketMessageDialog(true);
                                }}
                                fontSize="small"
                                style={{
                                    padding: 2,
                                    height: 23,
                                    width: 23,
                                    fontSize: 12,
                                    color: "#fff",
                                    cursor: "pointer",
                                    backgroundColor: blue[700],
                                    borderRadius: 50
                                }}
                            />
                        </Tooltip>
                    )}
                </div>
            </React.Fragment>
        );
    };

    if (!ticket) {
        return null;
    }

    return (
        <React.Fragment key={ticket.id}>
            <TicketMessagesDialog
                open={openTicketMessageDialog}
                handleClose={() => setOpenTicketMessageDialog(false)}
                ticketId={ticket.id}></TicketMessagesDialog>
            <ListItem
                dense
                button
                onClick={(e) => {
                    if (ticket.status === "pending") return;
                    handleSelectTicket(ticket);
                }}
                selected={ticketId && +ticketId === ticket.id}
                className={clsx(classes.ticket, {
                    [classes.pendingTicket]: ticket.status === "pending"
                })}>
                <Tooltip arrow placement="right" title={ticket.queue?.name || "Sem fila"}>
                    <span style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }} className={classes.ticketQueueColor}></span>
                </Tooltip>
                <ListItemAvatar>
                    <Avatar src={ticket?.contact?.profilePicUrl} />
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    primary={
                        <span className={classes.contactNameWrapper}>
                            <Typography noWrap component="span" variant="body2" color="textPrimary">
                                {ticket.channel === "whatsapp" && (
                                    <Tooltip title={`Atribuido à ${ticketUser}`}>
                                        <WhatsAppIcon fontSize="inherit" style={{ color: grey[700] }} />
                                    </Tooltip>
                                )}{" "}
                                {ticket.contact.name}
                            </Typography>
                        </span>
                    }
                    secondary={
                        <span className={classes.contactNameWrapper}>
                            <Typography className={classes.contactLastMessage} noWrap component="span" variant="body2" color="textSecondary">
                                {" "}
                                {ticket.lastMessage.includes("data:image/png;base64") ? (
                                    <MarkdownWrapper> Localização</MarkdownWrapper>
                                ) : (
                                    <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                                )}
                                {/* {ticket.lastMessage === "" ? <br /> : <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>} */}
                            </Typography>
                            <ListItemSecondaryAction style={{ left: 73 }}>
                                <Box className={classes.ticketInfo1}>{renderTicketInfo()}</Box>
                            </ListItemSecondaryAction>
                        </span>
                    }
                />
                <ListItemSecondaryAction style={{}}>
                    {ticket.status === "closed" && (
                        <Badge
                            className={classes.Radiusdot}
                            badgeContent={"FECHADO"}
                            //color="primary"
                            style={{
                                backgroundColor: ticket.queue?.color || "#ff0000",
                                height: 18,
                                padding: 5,
                                paddingHorizontal: 12,
                                borderRadius: 7,
                                color: "white",
                                top: -28,
                                marginRight: 5
                            }}
                        />
                    )}

                    {ticket.lastMessage && (
                        <>
                            <Typography className={classes.lastMessageTime} component="span" variant="body2" color="textSecondary">
                                {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                                    <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                                ) : (
                                    <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
                                )}
                            </Typography>

                            <Badge
                                className={classes.newMessagesCount}
                                badgeContent={ticket.unreadMessages ? ticket.unreadMessages : null}
                                classes={{
                                    badge: classes.badgeStyle
                                }}
                            />
                            <br />
                        </>
                    )}
                </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
        </React.Fragment>
    );
};

export default TicketListItemCustom;
