import { i18n } from "../../translate/i18n";
import Swal from "sweetalert2";
import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";

const ContactModalSchedule = ({ title, text, user, open, onClose }) => {
    const cancel = () => {
        onClose(false);
        return i18n.t("confirmationModal.buttons.cancel");
    };

    const showAlert = async () => {
        const result = await Swal.fire({
            icon: "warning",
            title: user === "admin" || user === "user" ? typeof title === "string" ? title : <DialogTitle>{title}</DialogTitle> : "",
            text: user === "admin" ? typeof text === "string" ? text : <DialogTitle>{text}</DialogTitle> : "",
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonColor: "#999D9E",
            cancelButtonText: cancel()
        });
        if (result.isConfirmed) {
        }
    };

    if (open) {
        showAlert();
    }

    return null;
};

export default ContactModalSchedule;
