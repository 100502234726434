import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge, Collapse, List } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import FlashOnIcon from "@material-ui/icons/OfflineBoltOutlined";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";
import EventIcon from "@material-ui/icons/Event";
import LocalOfferIcon from "@material-ui/icons/LocalOfferOutlined";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PeopleOutlinedIcon from "@material-ui/icons/PeopleOutlined";
import ListIcon from "@material-ui/icons/ListAlt";
import AnnouncementIcon from "@material-ui/icons/AnnouncementOutlined";
import ForumIcon from "@material-ui/icons/ForumOutlined";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { socketConnection } from "../services/socket";
import { isArray } from "lodash";
import api from "../services/api";
import toastError from "../errors/toastError";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    selected: {
        backgroundColor: "#f5f5f5",
        color: "#56be5e",
        borderRight: "#40b649 6px solid",
        transition: "all .2s ease",
        "& .MuiListItemIcon-root": {
            color: "#56be5e"
        }
    }
}));

function ListItemLink(props) {
    const { icon, primary, to, className, active = false } = props;
    const classes = useStyles();
    const renderLink = React.useMemo(
        () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} active={active} />),
        [to]
    );

    return (
        <li className={active ? classes.selected : ""}>
            <ListItem button component={renderLink} className={className}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
}

const reducer = (state, action) => {
    if (action.type === "LOAD_CHATS") {
        const chats = action.payload;
        const newChats = [];

        if (isArray(chats)) {
            chats.forEach((chat) => {
                const chatIndex = state.findIndex((u) => u.id === chat.id);
                if (chatIndex !== -1) {
                    state[chatIndex] = chat;
                } else {
                    newChats.push(chat);
                }
            });
        }

        return [...state, ...newChats];
    }

    if (action.type === "UPDATE_CHATS") {
        const chat = action.payload;
        const chatIndex = state.findIndex((u) => u.id === chat.id);

        if (chatIndex !== -1) {
            state[chatIndex] = chat;
            return [...state];
        } else {
            return [chat, ...state];
        }
    }

    if (action.type === "DELETE_CHAT") {
        const chatId = action.payload;

        const chatIndex = state.findIndex((u) => u.id === chatId);
        if (chatIndex !== -1) {
            state.splice(chatIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }

    if (action.type === "CHANGE_CHAT") {
        const changedChats = state.map((chat) => {
            if (chat.id === action.payload.chat.id) {
                return action.payload.chat;
            }
            return chat;
        });
        return changedChats;
    }
};

const MainListItems = (props) => {
    const { drawerClose } = props;
    const { whatsApps } = useContext(WhatsAppsContext);
    const { user } = useContext(AuthContext);
    const [connectionWarning, setConnectionWarning] = useState(false);
    const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
    const [showCampaigns, setShowCampaigns] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const [invisible, setInvisible] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam] = useState("");
    const [chats, dispatch] = useReducer(reducer, []);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchChats();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const companyId = localStorage.getItem("companyId");
        const socket = socketConnection({ companyId });

        socket.on(`company-${companyId}-chat`, (data) => {
            if (data.action === "new-message") {
                dispatch({ type: "CHANGE_CHAT", payload: data });
            }
            if (data.action === "update") {
                dispatch({ type: "CHANGE_CHAT", payload: data });
            }
        });
        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        let unreadsCount = 0;
        if (chats.length > 0) {
            for (let chat of chats) {
                for (let chatUser of chat.users) {
                    if (chatUser.userId === user.id) {
                        unreadsCount += chatUser.unreads;
                    }
                }
            }
        }
        if (unreadsCount > 0) {
            setInvisible(false);
        } else {
            setInvisible(true);
        }
    }, [chats, user.id]);

    useEffect(() => {
        const fetchCampaignConfig = async () => {
            if (!user.id) return;
            try {
                const { data } = await api.get(`/settings/byUser/${user.id}`);
                let keyValueCompany = {};
                let keyValueUser = {};
                if (isArray(data?.company)) {
                    keyValueCompany = Object.assign({}, ...data.company.map((x) => ({ [x.key]: x.value })));
                }

                if (isArray(data?.user)) {
                    keyValueUser = Object.assign({}, ...data.user.map((x) => ({ [x.key]: x.value })));
                }

                const isCampaignEnabled =
                    keyValueCompany["campaignsEnabled"]?.toLowerCase() === "true" && keyValueUser["campaignsEnabled"]?.toLowerCase() === "true"
                        ? true
                        : false;
                setShowCampaigns(isCampaignEnabled);
            } catch (err) {
                toastError(err);
            }
        };

        fetchCampaignConfig();
    }, [user.id]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (whatsApps.length > 0) {
                const offlineWhats = whatsApps.filter((whats) => {
                    return (
                        whats.status === "qrcode" ||
                        whats.status === "PAIRING" ||
                        whats.status === "DISCONNECTED" ||
                        whats.status === "TIMEOUT" ||
                        whats.status === "OPENING"
                    );
                });
                if (offlineWhats.length > 0) {
                    setConnectionWarning(true);
                } else {
                    setConnectionWarning(false);
                }
            }
        }, 2000);
        return () => clearTimeout(delayDebounceFn);
    }, [whatsApps]);

    const fetchChats = async () => {
        try {
            const { data } = await api.get("/chats/", {
                params: { searchParam, pageNumber }
            });
            dispatch({ type: "LOAD_CHATS", payload: data.records });
        } catch (err) {
            toastError(err);
        }
    };

    const checkActiveRoute = (route = "") => {
        return location.pathname.includes(route);
    };

    return (
        <div onClick={drawerClose}>
            <Can
                role={user.profile}
                perform="dashboard:view"
                yes={() => (
                    <ListItemLink to="/dashboard" primary="Dashboard" icon={<DashboardOutlinedIcon />} active={checkActiveRoute("/dashboard")} />
                )}
            />

            <ListItemLink
                to="/tickets"
                primary={i18n.t("mainDrawer.listItems.tickets")}
                icon={<WhatsAppIcon />}
                active={checkActiveRoute("/tickets")}
            />

            <ListItemLink
                to="/quick-messages"
                primary={i18n.t("mainDrawer.listItems.quickMessages")}
                icon={<FlashOnIcon />}
                active={checkActiveRoute("/quick-messages")}
            />

            <ListItemLink
                to="/contacts"
                primary={i18n.t("mainDrawer.listItems.contacts")}
                icon={<ContactPhoneOutlinedIcon />}
                active={checkActiveRoute("/contacts")}
            />

            {showCampaigns && (
                <React.Fragment>
                    <ListItem button onClick={() => setOpenCampaignSubmenu((prev) => !prev)} active={checkActiveRoute("/campaigns")}>
                        <ListItemIcon>
                            <EventAvailableIcon />
                        </ListItemIcon>
                        <ListItemText primary={i18n.t("mainDrawer.listItems.campaigns")} />
                        {openCampaignSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>
                    <Collapse style={{ paddingLeft: 15 }} in={openCampaignSubmenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemLink
                                to="/campaigns"
                                primary={i18n.t("mainDrawer.listItems.campaignsListItem.list")}
                                icon={<ListIcon />}
                                active={checkActiveRoute("/campaigns")}
                            />
                            <ListItemLink
                                to="/contact-lists"
                                primary={i18n.t("mainDrawer.listItems.campaignsListItem.listOfContacts")}
                                icon={<PeopleOutlinedIcon />}
                                active={checkActiveRoute("/contact-lists")}
                            />
                            <ListItemLink
                                to="/config-campaigns"
                                primary={i18n.t("mainDrawer.listItems.campaignsListItem.campaignConfig")}
                                icon={<SettingsOutlinedIcon />}
                                active={checkActiveRoute("/config-campaigns")}
                            />
                        </List>
                    </Collapse>
                </React.Fragment>
            )}

            <ListItemLink
                to="/schedules"
                primary={i18n.t("mainDrawer.listItems.schedules")}
                icon={<EventIcon />}
                active={checkActiveRoute("/schedules")}
            />

            <ListItemLink to="/tags" primary={i18n.t("mainDrawer.listItems.tags")} icon={<LocalOfferIcon />} active={checkActiveRoute("/tags")} />

            <ListItemLink
                to="/chats"
                primary={i18n.t("mainDrawer.listItems.chats")}
                icon={
                    <Badge color="secondary" variant="dot" invisible={invisible}>
                        <ForumIcon />
                    </Badge>
                }
                active={checkActiveRoute("/chats")}
            />

            <ListItemLink to="/helps" primary={i18n.t("mainDrawer.listItems.helps")} icon={<HelpOutlineIcon />} active={checkActiveRoute("/helps")} />

            <Can
                role={user.profile}
                perform="drawer-admin-items:view"
                yes={() => (
                    <>
                        <Divider />
                        <ListSubheader inset>{i18n.t("mainDrawer.listItems.administration")}</ListSubheader>
                        {user.super && (
                            <ListItemLink
                                to="/announcements"
                                primary={i18n.t("mainDrawer.listItems.annoucements")}
                                icon={<AnnouncementIcon />}
                                active={checkActiveRoute("/announcements")}
                            />
                        )}
                        <ListItemLink
                            to="/connections"
                            primary={i18n.t("mainDrawer.listItems.connections")}
                            icon={
                                <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                                    <SyncAltIcon />
                                </Badge>
                            }
                            active={checkActiveRoute("/connections")}
                        />
                        <ListItemLink
                            to="/queues"
                            primary={i18n.t("mainDrawer.listItems.queues")}
                            icon={<AccountTreeOutlinedIcon />}
                            active={checkActiveRoute("/queues")}
                        />
                        <ListItemLink
                            to="/users"
                            primary={i18n.t("mainDrawer.listItems.users")}
                            icon={<PeopleAltOutlinedIcon />}
                            active={checkActiveRoute("/users")}
                        />
                        <ListItemLink
                            to="/messages-api"
                            primary={i18n.t("mainDrawer.listItems.messagesAPI")}
                            icon={<CodeRoundedIcon />}
                            active={checkActiveRoute("/messages-api")}
                        />
                        <ListItemLink
                            to="/financeiro"
                            primary={i18n.t("mainDrawer.listItems.financeiro")}
                            icon={<LocalAtmIcon />}
                            active={checkActiveRoute("/financeiro")}
                        />

                        <ListItemLink
                            to="/settings"
                            primary={i18n.t("mainDrawer.listItems.settings")}
                            icon={<SettingsOutlinedIcon />}
                            active={checkActiveRoute("/settings")}
                        />
                        {}
                    </>
                )}
            />
        </div>
    );
};

export default MainListItems;
