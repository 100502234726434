import React, { useState, useEffect, useContext, useRef } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Grid, ListItemText, MenuItem, Select } from "@material-ui/core";
import { toast } from "react-toastify";
/* import FormHelperText from "@material-ui/core/FormHelperText"; */
import { useLocation } from "react-router-dom";
import ContactModalTicketVerification from "../ContactModalTicketVerification";

const filter = createFilterOptions({
    trim: true
});

const NewTicketModal = ({ modalOpen, onClose, initialContact, onAddMenssage }) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [selectedContact, setSelectedContact] = useState(null);
    const [selectedQueue, setSelectedQueue] = useState("");
    const [newContact, setNewContact] = useState({});
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const { user } = useContext(AuthContext);
    const { profile } = user;
    const [userQueueCount, setQueueCount] = useState(user.queues);
    const [registeredmessage, setRegisteredmessage] = useState("");
    const [confirmationRegisteredmessageTabTickets, setConfirmationRegisteredmessageTabTickets] = useState(false);
    const [confirmationRegisteredmessageTabContacts, setConfirmationRegisteredmessageTabContacts] = useState(false);
    const [errorSelectOptionQueue, setErrorSelectOptionQueue] = useState("");
    const [errorValidationMenssage, setErrorValidationMenssage] = useState("");
    const [validacionDigitContact, setValidacionDigitContact] = useState("");
    const [retornMensagem, setRetornMensagem] = useState(true);
    const localizacao = useLocation();
    const [searchContactSelected, setSearchContactSelected] = useState("");
    const [confirmContactTicket, setConfirmContactTicket] = useState(false);
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const [contactTicket, setContactTicket] = useState({});

    useEffect(() => {
        if (localizacao.pathname === "/contacts") {
            setConfirmationRegisteredmessageTabContacts(true);
        } else {
            setConfirmationRegisteredmessageTabTickets(true);
        }
        if (initialContact?.id !== undefined) {
            setOptions([initialContact]);
            setSelectedContact(initialContact);
        }
    }, [initialContact]);

    useEffect(() => {
        if (!modalOpen || searchParam.length < 3) {
            setLoading(false);
            return;
        }
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("contacts", {
                        params: { searchParam }
                    });

                    setOptions(data.contacts);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };

            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, modalOpen]);

    const handleClose = () => {
        onClose();
        setSearchParam("");
        setSelectedQueue("");
        setRegisteredmessage("");
        setSelectedContact(null);
        setValidacionDigitContact("");
        setErrorSelectOptionQueue("");
        setErrorValidationMenssage("");
        setRetornMensagem(true);
    };

    const mensagemValidatorAbaTickets = (message) => {
        if (message.trim().length == 0) {
            setRetornMensagem(true);
            setRegisteredmessage("");
        } else if (message.length > 0 && selectedContact === null && user.profile === "user") {
            setValidacionDigitContact("Aviso! Digite e selecione um contato válido");
            setRetornMensagem(true);
            setRegisteredmessage("");
        } else if (message.length > 0 && selectedQueue != "" && user.profile === "user" && selectedContact != null) {
            setErrorValidationMenssage("");
            setRegisteredmessage(message);
            setRetornMensagem(false);
        } else if (message.length > 0 && selectedQueue === "" && user.profile === "admin" && selectedContact != null) {
            setErrorValidationMenssage("");
            setRegisteredmessage(message);
            setRetornMensagem(false);
        } else if (message.length > 0 && selectedQueue != "" && user.profile === "admin" && selectedContact === null) {
            setErrorValidationMenssage("");
            setRegisteredmessage("");
            setRetornMensagem(true);
        } else if (message.length > 0 && selectedQueue === "" && user.profile === "admin" && selectedContact === null) {
            setValidacionDigitContact("Aviso! digite e selecione um contato válido");
            setRetornMensagem(true);
            setRegisteredmessage("");
        } else if (message.length > 0 && selectedQueue != "" && user.profile === "admin" && selectedContact != null) {
            setErrorValidationMenssage("");
            setRegisteredmessage(message);
            setRetornMensagem(false);
        }
    };

    const mensagemValidatorAbaContacts = (message) => {
        if (message.trim().length == 0) {
            setErrorValidationMenssage(true);
            setRegisteredmessage("");
            setRetornMensagem(true);
        } else if (message.length > 0 && selectedQueue === "" && user.profile === "user" && userQueueCount.length > 1) {
            setErrorValidationMenssage("");
            setErrorSelectOptionQueue("Aviso! selecione uma fila");
            setRegisteredmessage("");
            setRetornMensagem(true);
        } else if (message.length > 0 && selectedQueue === "" && user.profile === "user" && userQueueCount.length == 1) {
            setErrorValidationMenssage("");
            setErrorSelectOptionQueue("");
            setRegisteredmessage(message);
            setRetornMensagem(false);
        } else if (message.length > 0 && selectedQueue != "" && user.profile === "user") {
            setErrorValidationMenssage("");
            setErrorSelectOptionQueue("");
            setRegisteredmessage(message);
            setRetornMensagem(false);
        } else if (message.length > 0 && selectedQueue != "" && user.profile === "admin") {
            setErrorValidationMenssage("");
            setErrorSelectOptionQueue("");
            setRegisteredmessage(message);
            setRetornMensagem(false);
        } else if (message.length > 0 && selectedQueue === "" && user.profile === "admin") {
            setErrorValidationMenssage("");
            setErrorSelectOptionQueue("");
            setRegisteredmessage(message);
            setRetornMensagem(false);
        }
    };

    const validatorContactoption = (contact) => {
        setValidacionDigitContact("");
        if (user.profile === "user" && selectedQueue === "" && userQueueCount.length === 1) {
            setErrorSelectOptionQueue("Aviso! selecione Uma fila");
        } else if (user.profile === "user" && selectedQueue != "" && userQueueCount.length === 1) {
            setErrorSelectOptionQueue("");
            setErrorValidationMenssage("Digite uma mensagem!");
            setSearchParam(contact);
        } else if (user.profile === "user" && selectedQueue === "" && userQueueCount.length > 1) {
            setErrorValidationMenssage("");
            setErrorSelectOptionQueue("Aviso! selecione Uma fila");
            setSearchParam(contact);
        } else if (user.profile === "user" && selectedQueue != "" && userQueueCount.length > 1) {
            setErrorSelectOptionQueue("");
            setErrorValidationMenssage("Digite uma mensagem!");
            setSearchParam(contact);
        } else if (user.profile === "user" && selectedQueue === "" && retornMensagem === true) {
            setErrorSelectOptionQueue("");
            setErrorValidationMenssage("Digite uma mensagem!");
            setSearchParam(contact);
        } else if (user.profile === "user" && selectedQueue != "" && retornMensagem === true) {
            setErrorSelectOptionQueue("Aviso! selecione Uma fila");
            setSearchParam(contact);
        } else if (user.profile === "user" && selectedQueue != "") {
            setErrorSelectOptionQueue("");
            setValidacionDigitContact("");
            setSearchParam(contact);
        } else if (user.profile === "admin" && selectedQueue === "" && retornMensagem === true) {
            setErrorSelectOptionQueue("");
            setErrorValidationMenssage("Digite uma mensagem!");
            setSearchParam(contact);
        } else if (user.profile === "admin" && selectedQueue != "" && retornMensagem === true) {
            setErrorSelectOptionQueue("");
            setErrorValidationMenssage("");
            setSearchParam(contact);
        } else if (user.profile === "admin" && selectedQueue === "" && retornMensagem === true) {
            setErrorSelectOptionQueue("");
            setErrorValidationMenssage("");
            setSearchParam(contact);
        }
    };

    const validatorSelectOptionTabTickests = (selection) => {
        if (retornMensagem === true && selectedQueue === "" && user.profile === "user" && selectedContact === null) {
            setValidacionDigitContact("Aviso! digite e selecione um contato válido");
            setErrorSelectOptionQueue("");
            setSelectedQueue(selection);
            setRetornMensagem(true);
        } else if (retornMensagem === true && selectedQueue != "" && user.profile === "user" && selectedContact === null) {
            setErrorSelectOptionQueue("");
            setSelectedQueue(selection);
        } else if (retornMensagem === false && selectedQueue != "" && user.profile === "user" && selectedContact === null) {
            setErrorSelectOptionQueue("");
            setSelectedQueue(selection);
        } else if (retornMensagem === true && selectedQueue != "" && user.profile === "user" && selectedContact != null) {
            setErrorSelectOptionQueue("");
            setSelectedQueue(selection);
            /*  setRetornMensagem(false); */
        } else if (retornMensagem === true && selectedQueue === "" && user.profile === "user" && selectedContact != null) {
            setErrorSelectOptionQueue("");
            setSelectedQueue(selection);
            /* setRetornMensagem(false); */
        } else if (retornMensagem === true && selectedQueue === "" && user.profile === "admin" && selectedContact === null) {
            setValidacionDigitContact("Aviso! digite e selecione um contato válido");
            setSelectedQueue(selection);
        } else if (retornMensagem === true && selectedQueue === "" && user.profile === "admin" && selectedContact != null) {
            setErrorSelectOptionQueue("");
            setSelectedQueue(selection);
        } else if (retornMensagem === false && selectedQueue === "" && user.profile === "admin" && selectedContact != null) {
            setErrorSelectOptionQueue("");
            setSelectedQueue(selection);
        } else if (retornMensagem === false && selectedQueue != "" && user.profile === "admin" && selectedContact != null) {
            setErrorSelectOptionQueue("");
            setSelectedQueue(selection);
        } else if (retornMensagem === true && selectedQueue != "" && user.profile === "admin" && selectedContact === null) {
            setErrorSelectOptionQueue("");
            setSelectedQueue(selection);
        }
    };

    const validatorSelectOptionTabContats = (selection) => {
        if (retornMensagem === true && selection != "" && user.profile === "user") {
            setErrorSelectOptionQueue("");
            setSelectedQueue(selection);
        } else {
            setSelectedQueue(selection);
        }
    };

    const handleSaveTicket = async (contactId) => {
        if (!contactId) return;
        setLoading(true);
        try {
            const { data: ticket } = await api.post("/tickets", {
                contactId: contactId,
                queueId: selectedQueue === "" ? null : selectedQueue,
                userId: user.id,
                status: "open",
                username: `${user?.name.trim()}`,
                message: `*${user?.name.trim()}:*\n${registeredmessage}`
            });
            onClose(ticket);
            handleClose();
        } catch (err) {
            toastError(err);
            handleClose();
        }
        setLoading(false);
    };

    const handleSelectOption = (e, newValue) => {
        if (newValue?.number) {
            setValidacionDigitContact("");
            attendanceCheck(newValue);
            setSelectedContact(newValue);
        } else if (newValue?.name) {
            setNewContact({ name: newValue.name });
            setRetornMensagem(true);
            setContactModalOpen(true);
        }
    };

    const handleCloseContactModal = () => {
        setContactModalOpen(false);
    };

    const handleAddNewContactTicket = (contact) => {
        handleSaveTicket(contact.id);
        handleClose();
    };

    const createAddContactOption = (filterOptions, params) => {
        const filtered = filter(filterOptions, params);

        if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
            filtered.push({
                name: `${params.inputValue}`
            });
        }

        return filtered;
    };

    const renderOption = (option) => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${i18n.t("newTicketModal.add")} ${option.name}`;
        }
    };

    const renderOptionLabel = (option) => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${option.name}`;
        }
    };

    const attendanceCheck = async (contactSelected) => {
        try {
            const { data } = await api.get("/ticketsAttendanceCheck", {
                params: {
                    contactIdChecked: contactSelected.id,
                    companyIdChecked: contactSelected.companyId
                }
            });

            if (data.contact != null) {
                setSearchContactSelected(data.contact);
                setConfirmContactTicket(true);
                handleClose();
            } else {
                setContactTicket(contactSelected);
                setNewTicketModalOpen(true);
            }
        } catch (error) {
            toastError(error);
        }
    };

    const renderContactAutocomplete = () => {
        if (initialContact === undefined || initialContact.id === undefined) {
            return (
                <Grid xs={12} item>
                    <Autocomplete
                        fullWidth
                        options={options}
                        loading={loading}
                        clearOnBlur
                        autoHighlight
                        freeSolo
                        clearOnEscape
                        getOptionLabel={renderOptionLabel}
                        renderOption={renderOption}
                        filterOptions={createAddContactOption}
                        onChange={(e, newValue) => handleSelectOption(e, newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={i18n.t("newTicketModal.fieldLabel")}
                                autoFocus
                                variant="outlined"
                                error={validacionDigitContact !== ""}
                                helperText={validacionDigitContact}
                                onChange={(e) => {
                                    validatorContactoption(e.target.value);
                                }}
                                onKeyPress={(e) => {
                                    if (loading || !selectedContact) return;
                                    else if (e.key === "Enter") {
                                        handleSaveTicket(selectedContact.id);
                                    }
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    )
                                }}
                            />
                        )}
                    />
                </Grid>
            );
        }
        return null;
    };

    return (
        <>
            <ContactModal
                open={contactModalOpen}
                initialValues={newContact}
                onClose={handleCloseContactModal}
                onSave={handleAddNewContactTicket}
                onAddMenssage={retornMensagem}></ContactModal>
            <ContactModalTicketVerification
                title={` ${i18n.t("contacts.confirmationModal.ticketVerificationNoticeTitle")}`}
                text={
                    profile === "admin"
                        ? `${i18n.t("contacts.confirmationModal.ticketVerificationNoticeAdmin")}`
                        : `${i18n.t("contacts.confirmationModal.ticketVerificationNoticeUser")}`
                }
                contactToSearch={searchContactSelected}
                user={profile}
                open={confirmContactTicket}
                onClose={setConfirmContactTicket}></ContactModalTicketVerification>
            <Dialog open={modalOpen} onClose={handleClose}>
                <DialogTitle id="form-dialog-title">{i18n.t("newTicketModal.title")}</DialogTitle>
                <DialogContent dividers>
                    <Grid style={{ width: 300 }} container spacing={2}>
                        {renderContactAutocomplete()}
                        <Grid xs={12} item>
                            <Select
                                fullWidth
                                displayEmpty
                                variant="outlined"
                                value={selectedQueue}
                                error={errorSelectOptionQueue !== ""}
                                onChange={(e) => {
                                    if (localizacao.pathname === "/contacts") {
                                        validatorSelectOptionTabContats(e.target.value);
                                    } else {
                                        validatorSelectOptionTabTickests(e.target.value);
                                    }
                                }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                renderValue={() => {
                                    if (selectedQueue === "") {
                                        return "Selecione uma fila";
                                    }
                                    const queue = user.queues.find((q) => q.id === selectedQueue);
                                    return queue.name;
                                }}>
                                {user.queues?.length > 0 &&
                                    user.queues.map((queue, key) => (
                                        <MenuItem dense key={key} value={queue.id}>
                                            <ListItemText primary={queue.name} />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>
                        {confirmationRegisteredmessageTabContacts === true /* campo da da area de contatos */ && (
                            <Grid xs={12} item>
                                <TextField
                                    label={i18n.t("queueModal.form.greetingMessage")} /* enviando mensagem caso não tenha */
                                    name="text"
                                    variant="outlined"
                                    margin="dense"
                                    multiline
                                    fullWidth
                                    value={registeredmessage}
                                    error={errorValidationMenssage !== ""}
                                    helperText={errorValidationMenssage}
                                    required
                                    onChange={(e) => {
                                        mensagemValidatorAbaContacts(e.target.value);
                                    }}></TextField>
                            </Grid>
                        )}
                        {confirmationRegisteredmessageTabTickets === true /* campo da da area de tickets */ && (
                            <Grid xs={12} item>
                                <TextField
                                    label={i18n.t("queueModal.form.greetingMessage")}
                                    name="text"
                                    variant="outlined"
                                    margin="dense"
                                    multiline
                                    fullWidth
                                    value={registeredmessage}
                                    error={errorValidationMenssage !== ""}
                                    helperText={errorValidationMenssage}
                                    required
                                    onChange={(e) => {
                                        mensagemValidatorAbaTickets(e.target.value);
                                    }}></TextField>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" disabled={loading} variant="outlined">
                        {i18n.t("newTicketModal.buttons.cancel")}
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        type="button"
                        disabled={retornMensagem}
                        onClick={() => handleSaveTicket(selectedContact.id)}
                        color="primary"
                        style={{ color: "#FFFFFF" }}
                        loading={loading}>
                        {i18n.t("newTicketModal.buttons.ok")}
                    </ButtonWithSpinner>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default NewTicketModal;
