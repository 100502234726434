const telephoneNumberMasck = (number) => {
    let telephonew = number;

    // verifica se o numero de telefone contém 55
    if (telephonew.includes("55")) {
        // ira remover 55 , deixando formatação (XX) XXXX-XXXX
        telephonew = telephonew.replace(/\D/g, "");
        telephonew = telephonew.replace(/^55/, "");
        telephonew = telephonew.replace(/(\d{2})(\d)/, "($1) $2");
        telephonew = telephonew.replace(/(\d)(\d{4})$/, "$1-$2");

        return telephonew;
    } else {
        // apenas deixara a formatação como (XX) XXXX-XXXX
        telephonew = telephonew.replace(/\D/g, "");
        telephonew = telephonew.replace(/(\d{2})(\d)/, "($1) $2");
        telephonew = telephonew.replace(/(\d)(\d{4})$/, "$1-$2");
        return telephonew;
    }
};

export default telephoneNumberMasck;
