import React from "react";
import vCard from "vcf";
import { Avatar, makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import telephoneNumberMasck from "../ContactModal/telephoneNumberMasck";

const useStyles = makeStyles((theme) => ({
    contactCard: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: `10px 10px 10px 10px`,
        gap: 10
    },
    details: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },

    title: {
        fontSize: 18,
        fontWeight: "500"
    },

    details: {
        fontSize: 14
    }
}));

const RenderVcard = ({ vCardText, paddingBottom = 10 }) => {
    const classes = useStyles();

    try {
        let name = "";

        let phone = "";

        // converte as quebras de linhas para o padrão suportado pela biblioteca vcard
        vCardText = vCardText.replace(/\r?\n/g, "\r\n");

        const vcardParser = new vCard().parse(vCardText);
        // obtem o nome e o telefone do contato
        name = vcardParser.get("fn").valueOf() ?? "";
        phone = vcardParser.get("tel").valueOf() ?? "";

        // verifica se o numero
        // for um array, pois o telefone pode ser utilizado tanto como celular quanto como telefone
        // por exemplo:
        /* 
        -> PARA CELULAR


        [0]=> {type: "home",waid: "554884282967",_data: "+55 48 98428-2967",_field: "tel"} 
        
        -> PARA TELEFONE FIXO
        
        [1]=> {type: {[0]=> "cell",[1]=> "voice"},_data: "+55 48 3286-9894",_field: "tel"}

        */
        if (Array.isArray(phone)) {
            // caso seja um array, seleciona o primeira opção "celular" e obtem o numero
            phone = phone[0]._data;
        }

        // Copia o número de contato para o clipboard
        const copyToClipboard = (text) => {
            try {
                let telephone = telephoneNumberMasck(text);
                navigator.clipboard.writeText(telephone);
                toast.success(i18n.t("Número do contato copiado"));
            } catch (err) {
                toastError(err);
            }
        };

        return (
            <div className={classes.contactCard} style={{ paddingBottom: paddingBottom }}>
                <Avatar alt={name} src="/static/images/avatar/1.jpg" />
                <div className={classes.details}>
                    <div className={classes.title}>{name}</div>
                    <div className={classes.details} onClick={() => copyToClipboard(phone)} style={{ cursor: "pointer" }} title="Copiar número">
                        {phone}
                    </div>
                </div>
            </div>
        );
    } catch (error) {
        return (
            <div>
                Erro ao decodificar o contato enviado:
                <br></br>
                {vCardText}
            </div>
        );
    }
};

export default RenderVcard;
