import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { i18n } from "../../translate/i18n";
import Swal from "sweetalert2";
import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";

const ContactModalTicketVerification = ({ title, text, contactToSearch, user, open, onClose }) => {
    const history = useHistory();
    const goToTicket = () => {
        const ticketUrl = `/tickets/${contactToSearch.uuid}`;
        history.push(ticketUrl);
        onClose(false);
    };

    const cancel = () => {
        onClose(false);
        return i18n.t("confirmationModal.buttons.cancel");
    };

    const showAlert = () => {
        Swal.fire({
            icon: "warning",
            title: user === "admin" || user === "user" ? typeof title === "string" ? title : <DialogTitle>{title}</DialogTitle> : "",
            text: user === "admin" || user === "user" ? typeof text === "string" ? text : <DialogTitle>{text}</DialogTitle> : "",
            showCancelButton: true,
            confirmButtonColor: user === "admin" ? "#40b649" : "",
            cancelButtonColor: user === "user" ? "#40b649" : "#999D9E",
            reverseButtons: true,
            confirmButtonText: user === "admin" ? i18n.t("confirmationModal.buttons.confirm") : "",
            showConfirmButton: user === "admin",
            cancelButtonText: cancel()
        }).then((result) => {
            if (result.isConfirmed) {
                goToTicket();
            }
        });
    };

    if (open) {
        showAlert();
    }

    return null;
};

export default ContactModalTicketVerification;

// import React from "react";
// import Button from "@material-ui/core/Button";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import { makeStyles } from "@material-ui/core/styles";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// import { i18n } from "../../translate/i18n";

// const useStyles = makeStyles((theme) => ({
//     StylesOptionsUser: {
//         display: "flex",
//         flexDirection: "row",
//         alignItems: "center",
//         justifyContent: "center",
//         gap: 50,
//     },

//     StylesButton: {
//         width: 200,
//     },
// }));

// const ContactModalTicketVerification = ({ title, contactToSearch, user, open, onClose }) => {
//     const history = useHistory();

//     const changeToAlreadyTicketOpent = () => {
//         history.location.pathname = `/tickets/${contactToSearch.uuid}`;
//         history.push();
//         onClose(false);
//     };

//     const classes = useStyles();

//     return (
//         <Dialog
//             open={open}
//             onClose={() => onClose(false)}>
//             <DialogTitle>{title}</DialogTitle>
//             <DialogContent dividers>
//                 {user === "user" && (
//                     <DialogActions>
//                         <Button
//                             variant="contained"
//                             onClick={() => onClose(false)}
//                             color="default">
//                             {i18n.t("confirmationModal.buttons.cancel")}
//                         </Button>
//                     </DialogActions>
//                 )}
//                 {user === "admin" && (
//                     <DialogActions className={classes.StylesOptionsUser}>
//                         <Button
//                             variant="contained"
//                             className={classes.StylesButton}
//                             onClick={() => onClose(false)}
//                             color="default">
//                             {i18n.t("confirmationModal.buttons.negation")}
//                         </Button>
//                         <Button
//                             variant="contained"
//                             className={classes.StylesButton}
//                             onClick={changeToAlreadyTicketOpent}
//                             color="secondary">
//                             {i18n.t("confirmationModal.buttons.affirmation")}
//                         </Button>
//                     </DialogActions>
//                 )}
//             </DialogContent>
//         </Dialog>
//     );
// };

// export default ContactModalTicketVerification;
