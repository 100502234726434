import React from "react";
import { Button, Divider } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import ModalImageCors from "../ModalImageCors";
import LocationPreview from "../LocationPreview";
import RenderVcard from "../VcardPreview";
import toastError from "../../errors/toastError";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

const downloadFilesTxt = async (linkDowload, message) => {
    try {
        const cleanedJsonString = message.dataJson.replace(/\\/g, "");
        const obj = JSON.parse(cleanedJsonString);

        const response = await fetch(linkDowload);
        if (!response.ok) {
            throw new Error(`Erro ao fazer download do arquivo.`);
        }
        const txtContent = await response.text();
        const blob = new Blob([txtContent], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download =
            obj.message.documentMessage.fileName !== undefined &&
            obj.message.documentMessage.fileName !== null &&
            obj.message.documentMessage.fileName !== ""
                ? obj.message.documentMessage.fileName
                : "arquivo.txt";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    } catch (error) {
        toastError(error);
    }
};

// aqui está removendo os nomes dos arquivos que estão vindo juntos das mensagens dos usuarios
function treatmentFromMessageComingfromuser(mensagem) {
    return mensagem.replace(/^[^\n]*\n/, "");
}

const MediaRenderer = ({ message, classes }) => {
    const dataJson = message.dataJson;
    const fileNameMatch = dataJson.match(/"fileName":"([^"]*)"/);
    const fileName =
        fileNameMatch && fileNameMatch[1]
            ? fileNameMatch[1].length > 30
                ? `${fileNameMatch[1].substring(0, 30)}...${fileNameMatch[1].substring(fileNameMatch[1].length - 5)}`
                : fileNameMatch[1]
            : null;

    if ((message.mediaType === "locationMessage" || message.mediaType === "liveLocationMessage") && message.body.split("|").length >= 2) {
        let locationParts = message.body.split("|");
        let imageLocation = locationParts[0];
        let linkLocation = locationParts[1];
        let descriptionLocation = null;
        if (locationParts.length > 2) descriptionLocation = message.body.split("|")[2];
        return <LocationPreview image={imageLocation} link={linkLocation} description={descriptionLocation} />;
    } else if (message.mediaType === "contactMessage") {
        const messageDataJson = JSON.parse(message.dataJson);
        const contact = messageDataJson?.message?.contactMessage?.vcard ?? "";
        return <RenderVcard vCardText={contact} paddingBottom={0} />;
    } else if (message.mediaType === "contactsArrayMessage") {
        const messageDataJson = JSON.parse(message.dataJson);
        const contacts = messageDataJson?.message?.contactsArrayMessage?.contacts ?? [];
        return contacts.map((contact, index) => {
            return (
                <>
                    <RenderVcard vCardText={contact.vcard} paddingBottom={index === contacts.length - 1 ? 0 : undefined} />
                    {index < contacts.length - 1 && <Divider />}
                </>
            );
        });
    } else if (message.mediaType === "image") {
        return (
            <>
                <ModalImageCors imageUrl={message.mediaUrl} fromMe={message.fromMe} fileName={fileName} />
                {!message.fromMe && (
                    <>
                        <Divider />
                    </>
                )}
                <div className={classes.messageBodyDoc}>
                    {message.fromMe === false ? treatmentFromMessageComingfromuser(message.body) : message.body}
                </div>
            </>
        );
    } else if (message.mediaType === "audio") {
        return (
            <audio controls>
                <source src={message.mediaUrl} type="audio/ogg"></source>
            </audio>
        );
    } else if (message.mediaType === "video") {
        return (
            <>
                <video className={classes.messageMedia} src={message.mediaUrl} fromMe={message.fromMe} controls />
                {!message.fromMe && (
                    <>
                        <div className={classes.fileName}>{fileName}</div>
                        <Divider />
                    </>
                )}
                <div className={classes.messageBodyDoc}>
                    {message.fromMe === false ? treatmentFromMessageComingfromuser(message.body) : message.body}
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className={classes.downloadMedia} fromMe={message.fromMe}>
                    <div>
                        <InsertDriveFileOutlinedIcon />
                    </div>
                    <div className={classes.fileName}>
                        <div>{fileName}</div>
                    </div>
                    {message.mediaUrl.includes(".txt") === true ? (
                        <Button
                            startIcon={<GetApp />}
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                                downloadFilesTxt(message.mediaUrl, message);
                            }}>
                            Download
                        </Button>
                    ) : (
                        <Button startIcon={<GetApp />} color="primary" variant="outlined" target="_blank" href={message.mediaUrl}>
                            Download
                        </Button>
                    )}
                </div>
                <div className={classes.messageBodyDoc}>
                    {message.fromMe === false ? treatmentFromMessageComingfromuser(message.body) : message.body}
                </div>
            </>
        );
    }
};

export default MediaRenderer;
