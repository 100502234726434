import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Portal from "@material-ui/core/Portal";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import AnnouncementModal from "../../components/AnnouncementModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { Grid } from "@material-ui/core";
import { isArray } from "lodash";
import { socketConnection } from "../../services/socket";

const reducer = (state, action) => {
    switch (action.type) {
        case "LOAD_ANNOUNCEMENTS":
            const announcements = action.payload;
            const newAnnouncements = [];

            if (isArray(announcements)) {
                announcements.forEach((announcement) => {
                    const announcementIndex = state.findIndex((u) => u.id === announcement.id);
                    if (announcementIndex !== -1) {
                        state[announcementIndex] = announcement;
                    } else {
                        newAnnouncements.push(announcement);
                    }
                });
            }

            return [...state, ...newAnnouncements];

        case "UPDATE_ANNOUNCEMENTS":
            const announcement = action.payload;
            const announcementIndex = state.findIndex((u) => u.id === announcement.id);

            if (announcementIndex !== -1) {
                state[announcementIndex] = announcement;
                return [...state];
            } else {
                return [announcement, ...state];
            }

        case "DELETE_ANNOUNCEMENT":
            const announcementId = action.payload;
            return state.filter((u) => u.id !== announcementId);

        case "RESET":
            return [];

        default:
            return state;
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles
    }
}));

const Announcements = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
    const [deletingAnnouncement, setDeletingAnnouncement] = useState(null);
    const [announcementModalOpen, setAnnouncementModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [announcements, dispatch] = useReducer(reducer, []);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            fetchAnnouncements();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber]);

    const fetchAnnouncements = async () => {
        try {
            const { data } = await api.get("/announcements/", {
                params: { searchParam, pageNumber }
            });
            dispatch({ type: "LOAD_ANNOUNCEMENTS", payload: data.records });
            setHasMore(data.hasMore);
            setLoading(false);
        } catch (err) {
            toastError(err);
        }
    };

    useEffect(() => {
        fetchAnnouncements();
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const companyId = localStorage.getItem("companyId");
        const socket = socketConnection({ companyId });

        socket.on(`company-announcement`, (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_ANNOUNCEMENTS", payload: data.record });
            }
            if (data.action === "delete") {
                dispatch({ type: "DELETE_ANNOUNCEMENT", payload: +data.id });
            }
        });
        return () => {
            socket.disconnect();
        };
    }, []);

    const handleOpenAnnouncementModal = () => {
        setSelectedAnnouncement(null);
        setAnnouncementModalOpen(true);
    };

    const handleCloseAnnouncementModal = () => {
        setSelectedAnnouncement(null);
        setAnnouncementModalOpen(false);
    };

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleEditAnnouncement = (announcement) => {
        setSelectedAnnouncement(announcement);
        setAnnouncementModalOpen(true);
    };

    const handleDeleteAnnouncement = async (announcementId) => {
        try {
            await api.delete(`/announcements/${announcementId}`);
            toast.success(i18n.t("announcements.toasts.deleted"));
            dispatch({ type: "DELETE_ANNOUNCEMENT", payload: announcementId });
        } catch (err) {
            toastError(err);
        }
        setDeletingAnnouncement(null);
        setSearchParam("");
        setPageNumber(1);
        setConfirmModalOpen(false);
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const translatePriority = (val) => {
        if (val === 1) {
            return "Alta";
        }
        if (val === 2) {
            return "Média";
        }
        if (val === 3) {
            return "Baixa";
        }
    };

    return (
        <MainContainer>
            {confirmModalOpen && (
                <Portal>
                    <ConfirmationModal
                        title={selectedAnnouncement && `${i18n.t("announcements.confirmationModal.deleteTitle")} ${selectedAnnouncement.title}?`}
                        children={i18n.t("announcements.confirmationModal.deleteMessage")}
                        open={confirmModalOpen}
                        onClose={setConfirmModalOpen}
                        onConfirm={(e) => handleDeleteAnnouncement(deletingAnnouncement.id)}></ConfirmationModal>
                </Portal>
            )}
            <AnnouncementModal
                resetPagination={() => {
                    setPageNumber(1);
                    fetchAnnouncements();
                }}
                open={announcementModalOpen}
                onClose={handleCloseAnnouncementModal}
                aria-labelledby="form-dialog-title"
                announcementId={selectedAnnouncement && selectedAnnouncement.id}
            />
            <MainHeader>
                <Grid style={{ width: "99.6%" }} container>
                    <Grid xs={12} sm={8} item>
                        <Title>{i18n.t("announcements.title")}</Title>
                    </Grid>
                    <Grid xs={12} sm={4} item>
                        <Grid spacing={2} container>
                            <Grid xs={6} sm={6} item>
                                <TextField
                                    fullWidth
                                    placeholder={i18n.t("announcements.searchPlaceholder")}
                                    type="search"
                                    value={searchParam}
                                    onChange={handleSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{ color: "gray" }} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid xs={6} sm={6} item>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={handleOpenAnnouncementModal}
                                    color="primary"
                                    style={{ color: "#FFFFFF" }}>
                                    {i18n.t("announcements.buttons.add")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined" onScroll={handleScroll}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{i18n.t("announcements.table.title")}</TableCell>
                            <TableCell align="center">{i18n.t("announcements.table.priority")}</TableCell>
                            <TableCell align="center">{i18n.t("announcements.table.mediaName")}</TableCell>
                            <TableCell align="center">{i18n.t("announcements.table.status")}</TableCell>
                            <TableCell align="center">{i18n.t("announcements.table.actions")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {announcements.map((announcement) => (
                                <TableRow key={announcement.id}>
                                    <TableCell align="center">{announcement.title}</TableCell>
                                    <TableCell align="center">{translatePriority(announcement.priority)}</TableCell>
                                    <TableCell align="center">{announcement.mediaName ?? "Sem anexo"}</TableCell>
                                    <TableCell align="center">{announcement.status ? "ativo" : "inativo"}</TableCell>
                                    <TableCell align="center">
                                        <IconButton size="small" onClick={() => handleEditAnnouncement(announcement)}>
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                setConfirmModalOpen(true);
                                                setSelectedAnnouncement(announcement);
                                                setDeletingAnnouncement(announcement);
                                            }}>
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={5} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Announcements;
