import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";

import {
    makeStyles,
    Drawer,
    AppBar,
    Toolbar,
    List,
    Typography,
    Divider,
    MenuItem,
    IconButton,
    Menu,
    useTheme,
    useMediaQuery
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircle from "@material-ui/icons/AccountCircleOutlined";

import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";
import toastError from "../errors/toastError";
import AnnouncementsPopover from "../components/AnnouncementsPopover";

import logo from "../assets/LOGO_ZAVIX_EM_CURVAS_LATERAL.svg";
import { socketConnection } from "../services/socket";
import ChatPopover from "../pages/Chat/ChatPopover";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "100vh",
        [theme.breakpoints.down("sm")]: {
            height: "calc(100vh - 56px)"
        },
        backgroundColor: "#F5F5F5"
    },

    toolbar: {
        paddingRight: 24 // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        minHeight: "auto",
        paddingLeft: "26px"
    },
    appBar: {
        zIndex: 1,
        height: "48px",
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 36
    },
    menuButtonHidden: {
        display: "none"
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
        color: "#FFFFFF"
    },
    drawerPaper: {
        position: "relative",
        zIndex: 1,
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9)
        }
    },
    appBarSpacer: {
        minHeight: "48px"
    },
    content: {
        flex: 1,
        overflow: "auto",
        ...theme.scrollbarStyles
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column"
    },
    containerWithScroll: {
        flex: 1,
        paddingLeft: "8px",
        overflowX: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        ...theme.scrollbarStyles
    }
}));

const LoggedInLayout = ({ children }) => {
    const classes = useStyles();
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const { handleLogout, loading } = useContext(AuthContext);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerVariant, setDrawerVariant] = useState("permanent");
    const { user } = useContext(AuthContext);

    const theme = useTheme();
    const greaterThenSm = useMediaQuery(theme.breakpoints.up("sm"));

    useEffect(() => {
        if (document.body.offsetWidth > 600) {
            setDrawerOpen(true);
        }
    }, []);

    useEffect(() => {
        if (document.body.offsetWidth < 600) {
            setDrawerVariant("temporary");
        } else {
            setDrawerVariant("permanent");
        }
    }, [drawerOpen]);

    useEffect(() => {
        const companyId = localStorage.getItem("companyId");
        const userId = localStorage.getItem("userId");

        const socket = socketConnection({ companyId });

        socket.on(`company-${companyId}-auth`, (data) => {
            if (data.user.id === +userId) {
                toastError("Sua conta foi acessada em outro computador.");
                setTimeout(() => {
                    localStorage.clear();
                    window.location.reload();
                }, 1000);
            }
        });

        socket.emit("userStatus");
        const interval = setInterval(
            () => {
                socket.emit("userStatus");
            },
            1000 * 60 * 5
        );

        return () => {
            socket.disconnect();
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const handleOpenUserModal = () => {
        setUserModalOpen(true);
        handleCloseMenu();
    };

    const handleClickLogout = () => {
        handleCloseMenu();
        handleLogout();
    };

    const drawerClose = () => {
        if (document.body.offsetWidth < 600) {
            setDrawerOpen(false);
        }
    };

    if (loading) {
        return <BackdropLoading />;
    }

    return (
        <div className={classes.root}>
            <Drawer
                variant={drawerVariant}
                className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
                classes={{
                    paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose)
                }}
                open={drawerOpen}>
                <div className={classes.toolbarIcon}>
                    <img src={logo} style={{ display: "flex", alignItems: "flex-start", width: "50%" }} alt="logodash" />
                    <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List className={classes.containerWithScroll}>
                    <MainListItems drawerClose={drawerClose} />
                </List>
                <Divider />
            </Drawer>
            <UserModal open={userModalOpen} onClose={() => setUserModalOpen(false)} userId={user?.id} />
            <AppBar className={clsx(classes.appBar, drawerOpen && classes.appBarShift)} color="primary">
                <Toolbar variant="dense" className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        variant="contained"
                        aria-label="open drawer"
                        onClick={() => setDrawerOpen(!drawerOpen)}
                        className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}>
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="#ffffff" noWrap className={classes.title}>
                        {greaterThenSm ? (
                            <>
                                Boas-vindas, <b>{user.name}</b>.
                            </>
                        ) : (
                            user.name
                        )}
                    </Typography>
                    {user.id && <NotificationsPopOver />}

                    <AnnouncementsPopover />

                    <ChatPopover />

                    <div>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            variant="contained">
                            <AccountCircle style={{ color: "#FFFFFF" }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            open={menuOpen}
                            onClose={handleCloseMenu}>
                            <MenuItem onClick={handleOpenUserModal}>{i18n.t("mainDrawer.appBar.user.profile")}</MenuItem>
                            <MenuItem onClick={handleClickLogout}>{i18n.t("mainDrawer.appBar.user.logout")}</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />

                {children ? children : null}
            </main>
        </div>
    );
};

export default LoggedInLayout;
