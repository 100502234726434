import React, { useState, useEffect, useContext, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import useQueues from "../../hooks/useQueues";
import { i18n } from "../../translate/i18n";
import Select from "@material-ui/core/Select";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl, InputLabel, FormHelperText } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { AuthContext } from "../../context/Auth/AuthContext";
import { isArray, capitalize } from "lodash";
import ContactModalSchedule from "../../components/ContactModalSchedule";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
    },

    btnWrapper: {
        position: "relative"
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    }
}));
const ScheduleSchema = Yup.object().shape({
    body: Yup.string().min(5, "Mensagem muito curta").required("Obrigatório"),
    queueId: Yup.number().required("Fila é obrigatório"),
    userService: Yup.number().required("Usúario é obrigatório"),
    contactId: Yup.number().required("Contato é obrigatório"),
    sendAt: Yup.string().required("Obrigatório")
});

const ScheduleModal = ({ open, onClose, listSchedules, scheduleId, contactId, userService, cleanContact, cleanUser, reload, isReadOnly }) => {
    const classes = useStyles();
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const { profile } = user;
    const isMounted = useRef(true);
    const [registeredmessage, setRegisteredMessage] = useState("");

    const initialState = {
        body: "",
        contactId: "",
        queueId: "",
        userService: "",
        sendAt: moment().add(1, "hour").format("YYYY-MM-DDTHH:mm"),
        sentAt: ""
    };

    const initialContact = {
        id: "",
        name: ""
    };

    const initialUser = {
        id: "",
        name: ""
    };

    const [schedule, setSchedule] = useState(initialState);
    const [currentContact, setCurrentContact] = useState(initialContact);
    const [messagebody, setMessagebody] = useState("");
    const [contacts, setContacts] = useState([initialContact]);
    const [currentUser, setCurrentUser] = useState(initialUser);
    const [users, setUsers] = useState([initialUser]);
    const [queues, setQueues] = useState([]);
    const [selectedQueue, setSelectedQueue] = useState("");
    const { findAll: findAllQueues } = useQueues();

    const [confirmContactTicket, setConfirmContactTicket] = useState(false);
    const [searchContactSelected, setSearchContactSelected] = useState("");
    const [confirmationTitle, setConfirmationTitle] = useState("");
    const [confirmationText, setConfirmationText] = useState("");

    const manageName = (body, currentUser, user) => {
        const nomeAtual = `*${currentUser.name.trim()}:*\n`;
        const nomeNovo = `*${user ? user.name.trim() : ""}:*\n`;

        if (body.startsWith(nomeAtual)) {
            return body.replace(nomeAtual, nomeNovo);
        } else if (body.startsWith(nomeNovo)) {
            return body;
        } else {
            return `${body}`;
        }
    };

    const removeSignature = (body, userName) => {
        const signature = `*${userName.trim()}:*\n`;
        return body.startsWith(signature) ? body.replace(signature, "") : body;
    };

    useEffect(() => {
        if (userService && users.length) {
            const user = users.find((c) => c.id === userService);
            if (user) {
                setCurrentUser(user);
            }
        }
    }, [userService, users]);

    useEffect(() => {
        if (contactId && contacts.length) {
            const contact = contacts.find((c) => c.id === contactId);
            if (contact) {
                setCurrentContact(contact);
            }
        }
    }, [contactId, contacts]);

    useEffect(() => {
        if (schedule) {
            if (schedule.body != "") {
                setMessagebody(schedule.body);
            }
            if (schedule.userService) {
                const user = users.find((u) => u.id === schedule.userService);
                if (user) {
                    setCurrentUser(user);
                }
            }
            if (schedule.queueId) {
                setSelectedQueue(schedule.queueId);
            }
        }
    }, [schedule, users, contacts]);

    useEffect(() => {
        const { companyId } = user;
        if (open) {
            try {
                (async () => {
                    const { data: contactList } = await api.get("/contacts/list", { params: { companyId: companyId } });
                    let customList = contactList.map((c) => ({ id: c.id, name: c.name }));
                    const { data: userList } = await api.get("/users/list", { params: { companyId: companyId } });
                    let customUserList = userList.map((u) => ({ id: u.id, name: u.name }));
                    if (isArray(customList)) {
                        setContacts([{ id: "", name: "" }, ...customList]);
                        setUsers([{ id: "", name: "" }, ...customUserList]);
                    }
                    if (contactId) {
                        setSchedule((prevState) => {
                            return { ...prevState, contactId };
                        });
                    }

                    if (!scheduleId) return;

                    const { data } = await api.get(`/schedules/${scheduleId}`);
                    setSchedule((prevState) => {
                        return { ...prevState, ...data, sendAt: moment(data.sendAt).format("YYYY-MM-DDTHH:mm") };
                    });
                    setCurrentContact(data.contact);
                })();
            } catch (err) {
                toastError(err);
            }
        }
    }, [scheduleId, contactId, open, user]);

    useEffect(() => {
        if (schedule.body && !schedule.body.startsWith(`*${currentUser.name.trim()}:*`)) {
            setSchedule((prevState) => ({
                ...prevState,
                body: `*${currentUser.name.trim()}:*\n${registeredmessage}`
            }));
        }
    }, [registeredmessage, currentUser]);

    // Esta função verifica se o contato selecionado já existe um ticket em aberto
    const attendanceCheck = async (contact) => {
        try {
            const { data } = await api.get("/ticketsAttendanceCheck", {
                params: {
                    contactIdChecked: contact.id,
                    companyIdChecked: contact.companyId
                }
            });

            // Se a resposta da requisição for um contato, abre um modal de confirmação
            if (data.contact != null) {
                handleClose();
                setSearchContactSelected(data.contact);
                setConfirmationTitle("Este contato já possui um chamado em andamento");
                if (profile === "admin") {
                    setConfirmationText("Para acompanhar o progresso, vá até a aba 'Contatos' e procure pelo nome ou número do contato.");
                }
                setConfirmContactTicket(true);
            } else {
                //preciso chamar aqui
                searchScheduleByContact(contact.id, contact);
            }
        } catch (error) {
            toastError(error);
        }
    };

    const handleClose = () => {
        onClose();
        setSchedule(initialState);
        setCurrentContact(initialContact);
        setCurrentUser(initialUser);
        setSelectedQueue("");
        setMessagebody("");
    };

    //Caso o contato selecionado ja possua um agendamento em criado, abre um modal avisando.
    const searchScheduleByContact = (contactId, contact) => {
        const confirmation = listSchedules.find((schedule) => {
            return schedule.contact.id === contactId && schedule.status === "PENDENTE";
        });
        if (confirmation) {
            handleClose();
            setConfirmationTitle("Já existe um agendamento para este contato");
            setConfirmContactTicket(true);
        } else {
            setSchedule({ ...schedule, contactId: contactId });
            setCurrentContact(contact || initialContact);
        }
    };

    const handleSelectOption = (e, contact) => {
        if (contact && user) {
            contact.companyId = user.companyId;
            attendanceCheck(contact);
        }
    };

    const handleSaveSchedule = async (values) => {
        const scheduleData = {
            ...values,
            userId: user.id,
            body: values.body.startsWith(`*${currentUser.name.trim()}:*\n`) ? values.body : `*${currentUser.name.trim()}:*\n${values.body}`
        };
        try {
            if (scheduleId) {
                await api.put(`/schedules/${scheduleId}`, scheduleData);
            } else {
                await api.post("/schedules", scheduleData);
            }
            toast.success(i18n.t("scheduleModal.success"));
            if (typeof reload === "function") {
                reload();
            }
            if ((contactId, userService)) {
                if (typeof cleanContact === "function" && typeof cleanUser === "function") {
                    cleanContact();
                    cleanUser();
                    history.push("/schedules");
                }
            }
        } catch (err) {
            toastError(err);
        }
        setCurrentContact(initialContact);
        setCurrentUser(initialUser);
        setSchedule(initialState);
        handleClose();
    };

    useEffect(() => {
        if (isMounted.current) {
            const loadQueues = async () => {
                const list = await findAllQueues();
                setQueues(list);
            };
            loadQueues();
        }
    }, []);

    return (
        <div className={classes.root}>
            <ContactModalSchedule
                title={confirmationTitle}
                text={confirmationText}
                contactToSearch={searchContactSelected}
                user={profile}
                open={confirmContactTicket}
                onClose={setConfirmContactTicket}></ContactModalSchedule>
            <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {schedule.status === "ERRO" ? "Erro de Envio" : `Mensagem ${capitalize(schedule.status)}`}
                </DialogTitle>
                <Formik
                    initialValues={schedule}
                    enableReinitialize={true}
                    validationSchema={ScheduleSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveSchedule(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}>
                    {({ touched, errors, isSubmitting, values }) => (
                        <Form>
                            <DialogContent dividers>
                                <div className={classes.multFieldLine}>
                                    <FormControl variant="outlined" fullWidth error={touched.contactId && Boolean(errors.contactId)}>
                                        <Autocomplete
                                            fullWidth
                                            value={currentContact}
                                            options={contacts}
                                            onChange={(e, contact) => {
                                                if (!isReadOnly) handleSelectOption(e, contact);
                                            }}
                                            getOptionLabel={(option) => option.name}
                                            getOptionSelected={(option, value) => {
                                                return value.id === option.id;
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Contato *"
                                                    error={touched.contactId && Boolean(errors.contactId)}
                                                    helperText={touched.contactId && errors.contactId}
                                                    disabled={isReadOnly}
                                                />
                                            )}
                                            disabled={isReadOnly}
                                        />
                                    </FormControl>
                                </div>
                                <br />
                                <div className={classes.multFieldLine}>
                                    <FormControl variant="outlined" fullWidth error={touched.userService && Boolean(errors.userService)}>
                                        <InputLabel htmlFor="user-select">Usuário *</InputLabel>
                                        <Select
                                            fullWidth
                                            value={currentUser.id}
                                            onChange={(e) => {
                                                if (!isReadOnly) {
                                                    const user = users.find((u) => u.id === e.target.value);
                                                    setSchedule((prevState) => ({
                                                        ...prevState,
                                                        userService: e.target.value,
                                                        body: manageName(prevState.body, currentUser, user)
                                                    }));
                                                    setCurrentUser(user ? user : initialUser);
                                                }
                                            }}
                                            input={<OutlinedInput id="user-select" label="Usuário *" />}
                                            disabled={isReadOnly}>
                                            {users.map((user) => (
                                                <MenuItem key={user.id} value={user.id}>
                                                    {user.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{touched.userService && errors.userService}</FormHelperText>
                                    </FormControl>
                                </div>
                                <br />
                                <div className={classes.multFieldLine}>
                                    <FormControl
                                        variant="outlined"
                                        className={classes.maxWidth}
                                        fullWidth
                                        error={touched.queueId && Boolean(errors.queueId)}>
                                        <InputLabel>Fila *</InputLabel>
                                        <Select
                                            id="transferTicketModal.fieldQueuePlaceholder"
                                            value={selectedQueue}
                                            onChange={(e) => {
                                                if (!isReadOnly) {
                                                    setSelectedQueue(e.target.value);
                                                    setSchedule((prevState) => ({
                                                        ...prevState,
                                                        queueId: e.target.value,
                                                        body: prevState.body
                                                    }));
                                                }
                                            }}
                                            label="Fila *"
                                            disabled={isReadOnly}>
                                            {queues.map((queue) => (
                                                <MenuItem key={queue.id} value={queue.id}>
                                                    {queue.name}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                        {touched.queueId && errors.queueId && <FormHelperText>{errors.queueId}</FormHelperText>}
                                    </FormControl>
                                </div>
                                <br />
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        rows={9}
                                        multiline={true}
                                        label={i18n.t("scheduleModal.form.body")}
                                        name="body"
                                        error={touched.body && Boolean(errors.body)}
                                        helperText={touched.body && errors.body}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        value={removeSignature(messagebody, currentUser.name)}
                                        onChange={(e) => {
                                            if (!isReadOnly) {
                                                const value = e.target.value;
                                                setRegisteredMessage(value);
                                                setMessagebody(value);
                                                setSchedule((prevState) => ({
                                                    ...prevState,
                                                    body: removeSignature(value, currentUser.name)
                                                }));
                                            }
                                        }}
                                        disabled={isReadOnly}
                                    />
                                </div>
                                <br />
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("scheduleModal.form.sendAt")}
                                        type="datetime-local"
                                        name="sendAt"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        error={touched.sendAt && Boolean(errors.sendAt)}
                                        helperText={touched.sendAt && errors.sendAt}
                                        variant="outlined"
                                        fullWidth
                                        disabled={isReadOnly}
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary" variant="outlined">
                                    {i18n.t("scheduleModal.buttons.cancel")}
                                </Button>
                                {!isReadOnly && (
                                    <Button
                                        type="submit"
                                        color="primary"
                                        style={{ color: "#FFFFFF" }}
                                        disabled={isSubmitting}
                                        variant="contained"
                                        className={classes.btnWrapper}>
                                        {scheduleId ? `${i18n.t("scheduleModal.buttons.okEdit")}` : `${i18n.t("scheduleModal.buttons.okAdd")}`}
                                        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </Button>
                                )}
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default ScheduleModal;
