import nameMask from "./nameMask";
import api from "../../services/api";
import { toast } from "react-toastify";
import Box from "@material-ui/core/Box";
import listCountries from "./listCountries";
import { i18n } from "../../translate/i18n";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { FormControl } from "@material-ui/core";
import toastError from "../../errors/toastError";
import { green } from "@material-ui/core/colors";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, FieldArray, Form, Field } from "formik";
import telephoneNumberMasck from "./telephoneNumberMasck";
import React, { useState, useEffect, useRef, useContext } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ContactSchemaValidation from "./contactFieldsValidation";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },

    btnWrapper: {
        position: "relative",
        color: "#FFFFFF"
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },

    organizationofelements: {
        display: "flex",
        flexDirection: "row",
        width: 600,
        gap: 20
    }
}));

const ContactModal = ({ open, onClose, contactId, initialValues, onSave, onAddMenssage }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const countries = listCountries;
    const [validationInMessageCompletion, setValidationInMessageCompletion] = useState(true);
    const { user } = useContext(AuthContext);
    const [registeredmessage, setRegisteredmessage] = useState("");
    const [errorValidationMenssage, setErrorValidationMenssage] = useState("");

    const initialState = {
        name: "",
        email: "",
        number: ""
    };

    const [contact, setContact] = useState(initialState);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        const fetchContact = async () => {
            if (initialValues) {
                setContact((prevState) => {
                    return { ...prevState, ...initialValues };
                });
            }

            if (!contactId) return;

            try {
                const { data } = await api.get(`/contacts/${contactId}`);
                if (isMounted.current) {
                    data.name = nameMask(data.name);
                    data.number = telephoneNumberMasck(data.number);
                    setContact(data);
                }
            } catch (err) {
                toastError(err);
            }
        };

        fetchContact();
    }, [contactId, open, initialValues]);

    const handleClose = () => {
        onClose();
        setErrorValidationMenssage("");
        setContact(initialState);
    };

    const handleSaveContact = async (values) => {
        const numberFormated = values?.number;
        const menssageSend = values?.message;
        try {
            const nameContact = nameMask(values?.name);
            values.name = nameContact;
            if (contactId) {
                await api.put(`/contacts/${contactId}`, {
                    ...values,
                    number: `+55 ${numberFormated}`
                });

                handleClose();
            } else {
                await api.post("/ticketsSendMessage", {
                    menssageSend: `*${user?.name.trim()}:*\n${menssageSend}`
                });
                const { data } = await api.post("/contacts", {
                    ...values,
                    number: `+55 ${numberFormated}`
                });

                if (onSave) {
                    onSave(data);
                }

                handleClose();
            }

            toast.success(i18n.t("contactModal.success"));
        } catch (error) {
            toastError(error);
        }
    };

    const messageValidator = (message) => {
        if (message.trim().length == 0) {
            setRegisteredmessage("");
            setErrorValidationMenssage(true);
            setValidationInMessageCompletion(true);
        } else {
            setErrorValidationMenssage("");
            setRegisteredmessage(message);
            setValidationInMessageCompletion(false);
        }

        return message;
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {contactId ? `${i18n.t("contactModal.title.edit")}` : `${i18n.t("contactModal.title.add")}`}
                </DialogTitle>

                <Formik
                    initialValues={contact}
                    enableReinitialize={true}
                    validationSchema={ContactSchemaValidation}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveContact(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}>
                    {({ values, errors, touched, isSubmitting, handleChange }) => (
                        <Form>
                            <DialogContent dividers>
                                <Typography variant="subtitle1" gutterBottom>
                                    {i18n.t("contactModal.form.mainInfo")}
                                </Typography>
                                <div className={classes.organizationofelements}>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        value={values.name}
                                        label={i18n.t("contactModal.form.name")}
                                        name="name"
                                        autoFocus
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        margin="dense"
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                        required
                                    />
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        value={values.number}
                                        inputProps={{ maxLength: 15 }}
                                        label={i18n.t("contactModal.form.number")}
                                        name="number"
                                        placeholder="(XX) XXXXX-XXXX"
                                        variant="outlined"
                                        margin="dense"
                                        error={touched.number && Boolean(errors.number)}
                                        helperText={touched.number && errors.number}
                                        onChange={(e) => {
                                            e.target.value = telephoneNumberMasck(e.target.value);
                                            handleChange(e);
                                        }}
                                        required
                                    />
                                </div>
                                <div>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("contactModal.form.email")}
                                        name="email"
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                        placeholder="Endereço de e-mail"
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                    />
                                </div>
                                {onAddMenssage != undefined && (
                                    <div>
                                        <Field
                                            as={TextField}
                                            style={{ marginTop: 20 }}
                                            label={i18n.t("queueModal.form.greetingMessage")}
                                            name="message"
                                            variant="outlined"
                                            margin="dense"
                                            multiline
                                            fullWidth
                                            required
                                            value={registeredmessage}
                                            error={errorValidationMenssage !== ""}
                                            helperText={errorValidationMenssage}
                                            onChange={(e) => {
                                                e.target.value = messageValidator(e.target.value);
                                                handleChange(e);
                                            }}
                                        />
                                    </div>
                                )}
                                <Typography style={{ marginBottom: 8, marginTop: 12 }} variant="subtitle1">
                                    {i18n.t("contactModal.form.extraInfo")}
                                </Typography>

                                <FieldArray name="extraInfo">
                                    {({ push, remove }) => (
                                        <>
                                            {values.extraInfo &&
                                                values.extraInfo.length > 0 &&
                                                values.extraInfo.map((info, index) => (
                                                    <div className={classes.extraAttr} key={`${index}-info`}>
                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t("contactModal.form.extraName")}
                                                            name={`extraInfo[${index}].name`}
                                                            variant="outlined"
                                                            margin="dense"
                                                            className={classes.textField}
                                                        />
                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t("contactModal.form.extraValue")}
                                                            name={`extraInfo[${index}].value`}
                                                            variant="outlined"
                                                            margin="dense"
                                                            className={classes.textField}
                                                        />
                                                        <IconButton size="small" onClick={() => remove(index)}>
                                                            <DeleteOutlineIcon />
                                                        </IconButton>
                                                    </div>
                                                ))}
                                            <div className={classes.extraAttr}>
                                                <Button
                                                    style={{ flex: 1, marginTop: 8 }}
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => push({ name: "", value: "" })}>
                                                    {`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </FieldArray>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary" disabled={isSubmitting} variant="outlined">
                                    {i18n.t("contactModal.buttons.cancel")}
                                </Button>
                                {onAddMenssage === undefined && (
                                    <Button
                                        type="submit"
                                        color="primary"
                                        style={{ color: "#FFFFFF" }}
                                        disabled={isSubmitting}
                                        variant="contained"
                                        className={classes.btnWrapper}>
                                        {contactId ? `${i18n.t("contactModal.buttons.okEdit")}` : `${i18n.t("contactModal.buttons.okAdd")}`}
                                        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </Button>
                                )}
                                {onAddMenssage != undefined && (
                                    <Button
                                        type="submit"
                                        color="primary"
                                        style={{ color: "#FFFFFF" }}
                                        disabled={validationInMessageCompletion}
                                        variant="contained"
                                        className={classes.btnWrapper}>
                                        {contactId ? `${i18n.t("contactModal.buttons.okEdit")}` : `${i18n.t("contactModal.buttons.okAdd")}`}
                                        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </Button>
                                )}
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default ContactModal;
