const rules = {
    user: {
        static: []
    },

    admin: {
        static: [
            "dashboard:view",
            "drawer-admin-items:view",
            "tickets-manager:showall",
            "user-modal:editProfile",
            "user-modal:editQueues",
            "user-modal:enableCampaign",
            "ticket-options:deleteTicket",
            "contacts-page:deleteContact",
            "connections-page:actionButtons",
            "connections-page:addConnection",
            "connections-page:editOrDeleteConnection"
        ]
    }
};

export default rules;
