/* eslint-disable no-throw-literal */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
    maxWidth: {
        width: "100%"
    },
    displayButtons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center"
    }
}));

const AddCommentModal = ({ modalOpen, onClose, ticketid, userId, status }) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [commetUser, setCommetUser] = useState("");
    const [errorValidationCommet, setErrorValidationCommet] = useState("");
    const [ativationCommet, setAtivationCommet] = useState(true);
    const classes = useStyles();

    const handleClose = () => {
        onClose();
        setErrorValidationCommet("");
        setCommetUser("");
        setAtivationCommet(true);
    };

    const validateComment = (commet) => {
        let validate = commet.trim();
        if (validate !== "") {
            setErrorValidationCommet("");
            setCommetUser(commet);
            setAtivationCommet(false);
        } else if (validate === "") {
            setErrorValidationCommet("Preenchimento desse campo é obrigatorio!");
            setCommetUser(commet);
            setAtivationCommet(true);
        } else if (commet === "") {
            setAtivationCommet(true);
            setCommetUser(commet);
        }
    };

    const handleSaveTicket = async (e) => {
        e.preventDefault();
        let commetUserNoSideSpaces = commetUser.trim();
        try {
            if (!ticketid) return;
            setLoading(true);
            let data = {
                action: "comment",
                userId: userId,
                status: status,
                commetUser: commetUserNoSideSpaces
            };

            await api.put(`/tickets/${ticketid}`, data);
            history.push(`/tickets`);
        } catch (err) {
            toastError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
            <form onSubmit={handleSaveTicket}>
                <DialogTitle id="form-dialog-title">{i18n.t("CommentTicketModaL.titleComment")}</DialogTitle>
                <DialogContent dividers>
                    <FormControl variant="outlined" className={classes.maxWidth}>
                        <TextField
                            style={{ marginTop: 20, width: 300 }}
                            label={i18n.t("CommentTicketModaL.commentToBeTyped")}
                            name="text"
                            variant="outlined"
                            margin="dense"
                            multiline
                            fullWidth
                            value={commetUser}
                            error={errorValidationCommet !== ""}
                            helperText={errorValidationCommet}
                            onChange={(e) => {
                                validateComment(e.target.value);
                            }}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions className={classes.displayButtons}>
                    <Button onClick={handleClose} color="secondary" disabled={loading} variant="outlined">
                        {i18n.t("transferTicketModal.buttons.cancel")}
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={ativationCommet}
                        loading={loading}
                        style={{ color: "#FFFFFF" }}>
                        {i18n.t("transferTicketModal.buttons.annotate")}
                    </ButtonWithSpinner>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default AddCommentModal;
