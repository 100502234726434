import React, { useState, useEffect, useReducer, useContext } from "react";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import DeleteIcon from "@material-ui/icons/Delete";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ContactModal from "../../components/ContactModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import NewTicketModal from "../../components/NewTicketModal";
import { socketConnection } from "../../services/socket";
import ContactModalTicketVerification from "../../components/ContactModalTicketVerification";
import Swal from "sweetalert2";

const reducer = (state, action) => {
    switch (action.type) {
        case "LOAD_CONTACTS":
            const contacts = action.payload;
            const newContacts = [];
            contacts.forEach((contact) => {
                if (contact && contact.id) {
                    const contactIndex = state.findIndex((c) => c.id === contact.id);
                    if (contactIndex !== -1) {
                        state[contactIndex] = contact;
                    } else {
                        newContacts.push(contact);
                    }
                }
            });
            return [...state, ...newContacts];
        case "UPDATE_CONTACTS":
            const contact = action.payload;
            if (contact && contact.id) {
                const contactIndex = state.findIndex((c) => c.id === contact.id);
                if (contactIndex !== -1) {
                    state[contactIndex] = contact;
                    return [...state];
                } else {
                    return [contact, ...state];
                }
            }
            return state;
        case "DELETE_CONTACT":
            const contactId = action.payload;
            return state.filter((c) => c.id !== contactId);
        case "DELETE_MULTIPLE_CONTACTS":
            const contactIds = action.payload;
            return state.filter((c) => !contactIds.includes(c.id));
        case "RESET":
            return [];
        default:
            return state;
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        scrollbarWidth: "thin",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        ...theme.scrollbarStyles
    },
    tableHeader: {
        backgroundColor: "#E4EFF1",
        borderBottom: "2px solid #CCCCCC"
    },
    tableCell: {
        padding: theme.spacing(1),
        textAlign: "center"
    },
    tableRow: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover
        },
        "&:hover": {
            backgroundColor: theme.palette.action.selected
        }
    },
    waButton: {
        "&:hover": {
            color: theme.palette.primary.main
        }
    },
    editButton: {
        "&:hover": {
            color: theme.palette.primary.main
        }
    },
    deleteButton: {
        "&:hover": {
            color: theme.palette.error.main
        }
    },
    capitalizeText: {
        textTransform: "capitalize",
        padding: "6px 10px"
    },
    loadingOverlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        zIndex: 9999
    },
    spinnerText: {
        color: "#f3f3f3",
        fontSize: 20,
        marginBottom: 10,
        textShadow: "0 0 1px black",
        textRendering: "optimizeLegibility"
    },
    spinner: {
        border: "16px solid #f3f3f3",
        borderTop: "16px solid #40b649",
        borderRadius: "50%",
        width: 120,
        height: 120,
        animation: "$spin 2s linear infinite"
    },

    "@keyframes spin": {
        "0%": { transform: "rotate(0deg)" },
        "100%": { transform: "rotate(360deg)" }
    },
    searchInput: {
        maxWidth: 160
    }
}));

const Contacts = () => {
    const classes = useStyles();
    const history = useHistory();

    const { user } = useContext(AuthContext);
    const { profile } = user;

    const [loading, setLoading] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [contacts, dispatch] = useReducer(reducer, []);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const [contactTicket, setContactTicket] = useState({});
    const [deletingContact, setDeletingContact] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [file, setFile] = useState(null);
    const [actionType, setActionType] = useState(null);
    const [selectedContactIds, setSelectedContactIds] = useState([]);
    const [update, setUpdate] = useState(false);

    const handleSelectContact = (contactId) => {
        setSelectedContactIds((prevSelectedContactIds) =>
            prevSelectedContactIds.includes(contactId)
                ? prevSelectedContactIds.filter((id) => id !== contactId)
                : [...prevSelectedContactIds, contactId]
        );
    };

    const handleSelectAllContacts = (event) => {
        if (event.target.checked) {
            const allContactIds = contacts.map((contact) => contact.id);
            setSelectedContactIds(allContactIds);
        } else {
            setSelectedContactIds([]);
        }
    };

    const handleDeleteSelectedContacts = async () => {
        try {
            await Promise.all(selectedContactIds.map((contactId) => api.delete(`/contacts/${contactId}`)));
            toast.success("Contatos deletados com sucesso!");
            dispatch({ type: "DELETE_MULTIPLE_CONTACTS", payload: selectedContactIds });
            setSelectedContactIds([]);
            setSearchParam("");
            setPageNumber(1);
            setUpdate((prev) => !prev);
        } catch (err) {
            toastError(err);
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (!selectedFile.name.endsWith(".csv")) {
            toast.error("Por favor, selecione um arquivo CSV válido.");
            return;
        }
        setFile(selectedFile);
        setActionType("import");
        setConfirmOpen(true);
    };

    const handleImportContact = async () => {
        if (!file) {
            return toast.error("Select a file first.");
        }
        setLoading(true);
        setLoadingFile(true);
        const formData = new FormData();
        formData.append("file", file);

        try {
            const { data } = await api.post("/contacts/import", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            if (data.errors && data.errors.length > 0) {
                const errorMessage = data.errors.map((error) => `<li>${error}</li>`).join("");
                Swal.fire({
                    html: `<h3>Erro ao importar contatos. Verifique os erros e tente novamente:</h3><ul>${errorMessage}</ul>`,
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#40b649",
                    confirmButtonText: "Confirm"
                });
            } else {
                toast.success("Contatos importados com sucesso!!");
            }
        } catch (err) {
            /* console.log(err.response?.data?.errors); */
            //i18n.t(`backendErrors.${error}`)
            console.log(err.response?.data?.errors);
            const errorMessage = err.response?.data?.errors
                ?.map((error) => {
                    const regexContato = /Contato:([^;]*)/;
                    const matchContato = error.match(regexContato);

                    const regexNumero = /Nº:\s*([\d]+)/;
                    const matchNumero = error.match(regexNumero);

                    const regexErro = /Erro:\s*(.*)/;
                    const matchErro = error.match(regexErro);

                    return matchErro
                        ? `<li>${i18n.t(`backendErrors.${matchErro[1]}`)}<br>Contato: ${matchContato[1].trim()}<br>Número: ${matchNumero[1]}</li>`
                        : `<li>${i18n.t(`backendErrors.${error}`)}</li>`;
                })
                .join("");

            Swal.fire({
                html: `<h3>Erro ao importar contatos. Verifique os erros e tente novamente:</h3><ul>${errorMessage}</ul>`,
                icon: "error",
                showCancelButton: false,
                confirmButtonColor: "#40b649",
                confirmButtonText: "Confirm"
            });
        } finally {
            setSearchParam(" ");
            setSearchParam("");
            setFile(null);
            setActionType(null);
            setConfirmOpen(false);
            setLoading(false);
            setLoadingFile(false);
        }
    };

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    let params = { searchParam, pageNumber };

                    const { data } = await api.get("/contacts/", {
                        params
                    });

                    dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, update]);

    useEffect(() => {
        const companyId = localStorage.getItem("companyId");
        const socket = socketConnection({ companyId });

        socket.on(`company-${companyId}-contact`, (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleOpenContactModal = () => {
        setSelectedContactId(null);
        setContactModalOpen(true);
    };

    const handleCloseContactModal = () => {
        setSelectedContactId(null);
        setContactModalOpen(false);
    };

    const CustomUploadButton = ({ onChange }) => {
        return (
            <Button
                className={classes.capitalizeText}
                variant="contained"
                color="primary"
                style={{ color: "#FFFFFF" }}
                component="label"
                htmlFor="upload-file">
                Importar CSV
                <input id="upload-file" type="file" accept=".csv" hidden onChange={onChange} style={{ display: "none" }} />
            </Button>
        );
    };

    const handleCloseOrOpenTicket = (ticket) => {
        setNewTicketModalOpen(false);
        if (ticket !== undefined && ticket.uuid !== undefined) {
            history.push(`/tickets/${ticket.uuid}`);
        }
    };

    const hadleEditContact = (contactId) => {
        setSelectedContactId(contactId);
        setContactModalOpen(true);
    };

    const handleDeleteContact = async (contactId) => {
        try {
            await api.delete(`/contacts/${contactId}`);
            toast.success(i18n.t("contacts.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setSearchParam("");
        setPageNumber(1);
        setConfirmOpen(false);
    };

    const handleImportFromPhone = async () => {
        try {
            await api.post("/contacts/import");
            history.go(0);
        } catch (err) {
            toastError(err);
        }
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };
    const changeToAlreadyTicketOpent = (dados) => {
        history.location.pathname = `/tickets/${dados.uuid}`;
        history.push();
    };

    const attendanceCheck = async (contactSelected) => {
        try {
            const { data } = await api.get("/ticketsAttendanceCheck", {
                params: {
                    contactIdChecked: contactSelected.id,
                    companyIdChecked: contactSelected.companyId
                }
            });

            if (profile === "admin" && data.contact != null) {
                const result = await Swal.fire({
                    title: i18n.t("contacts.confirmationModal.ticketVerificationNoticeTitle"),
                    text: i18n.t("Esse contato já possui um chamado aberto. Gostaria de visualizar?"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#40b649",
                    cancelButtonColor: "#999D9E",
                    reverseButtons: true,
                    confirmButtonText: "Sim",
                    cancelButtonText: "Cancelar"
                });
                if (result.isConfirmed) {
                    changeToAlreadyTicketOpent(data.contact);
                }
            } else if (profile === "user" && data.contact != null) {
                await Swal.fire({
                    title: i18n.t("contacts.confirmationModal.ticketVerificationNoticeTitle"),
                    text: i18n.t("contacts.confirmationModal.ticketVerificationNoticeUser"),
                    icon: "warning",
                    showCancelButton: false,
                    confirmButtonColor: "#999D9E",
                    confirmButtonText: "Fechar"
                });
            } else {
                setContactTicket(contactSelected);
                setNewTicketModalOpen(true);
            }
        } catch (error) {
            toastError(error);
        }
    };

    return (
        <MainContainer className={classes.mainContainer}>
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                initialContact={contactTicket}
                onClose={(ticket) => {
                    handleCloseOrOpenTicket(ticket);
                }}
            />
            <ContactModal
                open={contactModalOpen}
                onClose={handleCloseContactModal}
                aria-labelledby="form-dialog-title"
                contactId={selectedContactId}></ContactModal>
            <ContactModalTicketVerification
                title={` ${i18n.t("contacts.confirmationModal.ticketVerificationNoticeTitle")}`}
                text={
                    profile === "admin"
                        ? ` ${i18n.t("contacts.confirmationModal.ticketVerificationNoticeAdmin")}`
                        : `${i18n.t("contacts.confirmationModal.ticketVerificationNoticeUser")}`
                }
                contactToSearch={selectedContactId}
                user={profile}></ContactModalTicketVerification>

            <ConfirmationModal
                title={
                    actionType === "import"
                        ? `${i18n.t("contacts.confirmationModal.importTitlte")}`
                        : actionType === "delete"
                          ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${deletingContact.name}?`
                          : actionType === "importFromPhone"
                            ? `${i18n.t("contacts.confirmationModal.importFromPhoneTitle")}`
                            : ""
                }
                open={confirmOpen}
                icon="question"
                onClose={() => setConfirmOpen(false)}
                onConfirm={() => {
                    actionType === "import"
                        ? handleImportContact()
                        : actionType === "delete"
                          ? handleDeleteContact(deletingContact.id)
                          : actionType === "importFromPhone"
                            ? handleImportFromPhone()
                            : null;
                }}>
                {actionType === "import"
                    ? `${i18n.t("contacts.confirmationModal.importMessage")}`
                    : actionType === "delete"
                      ? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
                      : actionType === "importFromPhone"
                        ? `${i18n.t("contacts.confirmationModal.importFromPhoneMessage")}`
                        : ""}
            </ConfirmationModal>
            <MainHeader>
                <Title>{i18n.t("contacts.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        className={classes.searchInput}
                        placeholder={i18n.t("contacts.searchPlaceholder")}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ color: "gray" }} />
                                </InputAdornment>
                            )
                        }}
                    />
                    <CustomUploadButton onChange={handleFileChange} />
                    <Button
                        className={classes.capitalizeText}
                        variant="contained"
                        color="primary"
                        style={{ color: "#FFFFFF" }}
                        onClick={() => {
                            setActionType("importFromPhone");
                            setConfirmOpen(true);
                        }}>
                        {i18n.t("contacts.buttons.import")}
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.capitalizeText}
                        color="primary"
                        style={{ color: "#FFFFFF" }}
                        onClick={handleOpenContactModal}>
                        {i18n.t("contacts.buttons.add")}
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.capitalizeText}
                        style={{ color: "#FFFFFF" }}
                        onClick={handleDeleteSelectedContacts}
                        disabled={selectedContactIds.length === 0}>
                        <DeleteIcon />
                        {i18n.t("contacts.buttons.delete")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined" onScroll={handleScroll}>
                <Table size="small">
                    <TableHead>
                        <TableRow className={classes.tableHeader}>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={selectedContactIds.length > 0 && selectedContactIds.length < contacts.length}
                                    checked={contacts.length > 0 && selectedContactIds.length === contacts.length}
                                    onChange={handleSelectAllContacts}
                                />
                            </TableCell>
                            <TableCell className={classes.tableCell} padding="checkbox" />
                            <TableCell className={classes.tableCell}>{i18n.t("contacts.table.name")}</TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                {i18n.t("contacts.table.whatsapp")}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                {i18n.t("contacts.table.email")}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                {i18n.t("contacts.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {contacts.map((contact) => (
                                <TableRow className={classes.tableRow} key={contact.id}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedContactIds.includes(contact.id)}
                                            onChange={() => handleSelectContact(contact.id)}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{ paddingRight: 0 }}>
                                        <Avatar src={contact.profilePicUrl} />
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>{contact.name}</TableCell>
                                    <TableCell className={classes.tableCell} align="center">
                                        {contact.numberFormated}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="center">
                                        {contact.email}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="center">
                                        <IconButton
                                            size="small"
                                            className={classes.waButton}
                                            title="Criar Ticket"
                                            onClick={() => attendanceCheck(contact)}>
                                            <WhatsAppIcon />
                                        </IconButton>
                                        <IconButton
                                            className={classes.editButton}
                                            title="Editar"
                                            size="small"
                                            onClick={() => hadleEditContact(contact.id)}>
                                            <EditIcon />
                                        </IconButton>
                                        <Can
                                            role={user.profile}
                                            perform="contacts-page:deleteContact"
                                            yes={() => (
                                                <IconButton
                                                    size="small"
                                                    className={classes.deleteButton}
                                                    title="Deletar"
                                                    onClick={() => {
                                                        setDeletingContact(contact);
                                                        setActionType("delete");
                                                        setConfirmOpen(true);
                                                    }}>
                                                    <DeleteOutlineIcon />
                                                </IconButton>
                                            )}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton avatar columns={3} />}
                            {loadingFile && (
                                <div className={classes.loadingOverlay}>
                                    <div className={classes.spinnerText}>Carregando contatos...</div>
                                    <div className={classes.spinner}></div>
                                </div>
                            )}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Contacts;
