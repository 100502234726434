import React from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import emptyChat from "../../assets/chat_empty.svg";
import TicketsManager from "../../components/TicketsManagerTabs/";
import Ticket from "../../components/Ticket/";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    chatContainer: {
        flex: 1,
        // backgroundColor: "#eee",
        padding: theme.spacing(1),
        height: `calc(100% - 48px)`,
        overflowY: "hidden"
    },

    chatPapper: {
        // backgroundColor: "red",
        display: "flex",
        height: "100%"
    },

    contactsWrapper: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflowY: "hidden"
    },
    messagesWrapper: {
        display: "flex",
        height: "100%",
        flexDirection: "column"
    },
    welcomeMsg: {
        backgroundColor: "#eee",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        textAlign: "center"
    }
}));

const TicketsCustom = () => {
    const classes = useStyles();
    const { ticketId } = useParams();

    return (
        <div className={classes.chatContainer}>
            <div className={classes.chatPapper}>
                <Grid container spacing={0}>
                    <Grid item xs={4} className={classes.contactsWrapper}>
                        <TicketsManager />
                    </Grid>
                    <Grid item xs={8} className={classes.messagesWrapper}>
                        {ticketId ? (
                            <>
                                <Ticket />
                            </>
                        ) : (
                            <Paper square variant="outlined" className={classes.welcomeMsg}>
                                <img src={emptyChat} alt="logo" className={classes.emptyChat} />
                                <span>{i18n.t("chat.noTicketMessage")}</span>
                            </Paper>
                        )}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default TicketsCustom;
