import React, { useContext, useEffect, useRef, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import TransferTicketModalCustom from "../TransferTicketModalCustom";
import AddCommentModal from "../AddCommentModal";
import toastError from "../../errors/toastError";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";

import ScheduleModal from "../ScheduleModal";

const TicketOptionsMenu = ({ ticket, menuOpen, handleClose, anchorEl }) => {
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
    const [transferAddCommetModal, setTransferAddCommetModal] = useState(false);
    const isMounted = useRef(true);
    const { user } = useContext(AuthContext);

    const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
    const [contactId, setContactId] = useState(null);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleDeleteTicket = async () => {
        try {
            await api.delete(`/tickets/${ticket.id}`);
        } catch (err) {
            toastError(err);
        }
        setConfirmationOpen(false);
    };

    const handleOpenConfirmationModal = (e) => {
        setConfirmationOpen(true);
        handleClose();
    };

    const handleOpenTransferModal = (e) => {
        setTransferTicketModalOpen(true);
        handleClose();
    };

    const handleOpenTransferAddCommetModal = (e) => {
        setTransferAddCommetModal(true);
    };

    const handleCloseTransferTicketModal = () => {
        if (isMounted.current) {
            setTransferTicketModalOpen(false);
        }
    };

    const handleCloseTransferAddCommetModal = () => {
        setTransferAddCommetModal(false);
    };

    const handleOpenScheduleModal = () => {
        handleClose();
        setContactId(ticket.contact.id);
        setScheduleModalOpen(true);
    };

    const handleCloseScheduleModal = () => {
        setScheduleModalOpen(false);
        setContactId(null);
    };

    return (
        <>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                open={menuOpen}
                onClose={handleClose}>
                <MenuItem onClick={handleOpenTransferModal}>{i18n.t("ticketOptionsMenu.transfer")}</MenuItem>
                <MenuItem onClick={handleOpenTransferAddCommetModal}>{i18n.t("ticketOptionsMenu.comment")}</MenuItem>
                <Can
                    role={user.profile}
                    perform="ticket-options:deleteTicket"
                    yes={() => <MenuItem onClick={handleOpenConfirmationModal}>{i18n.t("ticketOptionsMenu.delete")}</MenuItem>}
                />
            </Menu>
            <ConfirmationModal
                title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}${ticket.id} ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${ticket.contact.name}?`}
                open={confirmationOpen}
                onClose={setConfirmationOpen}
                onConfirm={handleDeleteTicket}>
                {i18n.t("ticketOptionsMenu.confirmationModal.message")}
            </ConfirmationModal>
            <TransferTicketModalCustom
                modalOpen={transferTicketModalOpen}
                onClose={handleCloseTransferTicketModal}
                ticketid={ticket.id}
                status={ticket.status}
            />
            <AddCommentModal
                modalOpen={transferAddCommetModal}
                onClose={handleCloseTransferAddCommetModal}
                userId={ticket.userId}
                ticketid={ticket.id}
                status={ticket.status}
            />
            <ScheduleModal open={scheduleModalOpen} onClose={handleCloseScheduleModal} aria-labelledby="form-dialog-title" contactId={contactId} />
        </>
    );
};

export default TicketOptionsMenu;
