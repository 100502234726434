import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import NewTicketModal from "../NewTicketModal";
import TicketsListCustom from "../TicketsListCustom";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { isArray } from "lodash";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { Button } from "@material-ui/core";
import { TagsFilter } from "../TagsFilter";
import UserDropdownSelect from "../UserDropdownSelect";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import Add from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    ticketsWrapper: {
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
    },

    tabsHeader: {
        flex: "none",
        borderBottom: "2px solid rgba(0, 0, 0, 0.12)"
    },

    settingsIcon: {
        alignSelf: "center",
        marginLeft: "auto",
        padding: 8
    },

    tab: {
        minWidth: "auto",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor: "#ffffff",
        fontSize: `calc(${6}px + 0.8vmin)`,
        margin: 2,
        marginBottom: -2,
        borderRadius: 2,
        transition: "all 0.3s ease",
        "&:hover, &.Mui-selected": {
            backgroundColor: "#f0f0f0"
        }
    },

    tabs: {
        minWidth: "auto",
        margin: "0 2px",
        borderRadius: 2,
        transition: "all 0.3s ease",
        backgroundColor: "#ffffff",
        "&:hover, &.Mui-selected": {
            backgroundColor: "#f0f0f0"
        }
    },

    ticketOptionsBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "#fafafa",
        padding: theme.spacing(1),
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
    },

    serachInputWrapper: {
        flex: 1,
        background: "#fff",
        display: "flex",
        borderRadius: 40,
        padding: 4,
        marginRight: theme.spacing(1)
    },

    searchIcon: {
        color: "grey",
        marginLeft: 6,
        marginRight: 6,
        alignSelf: "center"
    },

    searchInput: {
        flex: 1,
        border: "none",
        borderRadius: 30
    },

    badge: {
        marginTop: 4
    },
    show: {
        display: "block"
    },
    hide: {
        display: "none !important"
    },
    messageOfNotValidate: {
        color: "red",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#fafafa",
        padding: 5
    },
    showAllSwitch: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column-reverse",
        justifyContent: "space-between",
        marginLeft: -10,
        marginTop: 10,
        "& .MuiFormControlLabel-label": {
            fontSize: `calc(${10}px + 0.5vmin)`,
            fontWeight: 500,
            margin: "-8px"
        }
    }
}));

const TicketsManagerTabs = () => {
    const classes = useStyles();
    const history = useHistory();

    const [searchParam, setSearchParam] = useState("");
    const [searchParamValited, setSearchParamValited] = useState(false);
    const [tab, setTab] = useState("open");
    const [redirect, setRedirect] = useState(false);
    const [tabOpen, setTabOpen] = useState("open");
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const [showAllTickets, setShowAllTickets] = useState(false);
    const searchInputRef = useRef();
    const { user } = useContext(AuthContext);
    const { profile } = user;

    const [openCount, setOpenCount] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);
    const [campaignCount, setCampaignCount] = useState(0);

    const userQueueIds = user.queues.map((q) => q.id);
    const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
    const [selectedTags, setSelectedTags] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [showCampaigns, setShowCampaigns] = useState(false);

    useEffect(() => {
        if (user.profile.toUpperCase() === "ADMIN") {
            setShowAllTickets(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchCampaignConfig = async () => {
            if (!user.id) return;
            try {
                const { data } = await api.get(`/settings/byUser/${user.id}`);
                let keyValueCompany = {};
                let keyValueUser = {};
                if (isArray(data?.company)) {
                    keyValueCompany = Object.assign({}, ...data.company.map((x) => ({ [x.key]: x.value })));
                }

                if (isArray(data?.user)) {
                    keyValueUser = Object.assign({}, ...data.user.map((x) => ({ [x.key]: x.value })));
                }

                const isCampaignEnabled =
                    keyValueCompany["campaignsEnabled"]?.toLowerCase() === "true" && keyValueUser["campaignsEnabled"]?.toLowerCase() === "true"
                        ? true
                        : false;
                setShowCampaigns(isCampaignEnabled);
            } catch (err) {
                toastError(err);
            }
        };

        fetchCampaignConfig();
    }, [user.id]);

    useEffect(() => {
        if (tab === "open") {
            loadUsers();
        }
        if (tab === "search") {
            searchInputRef.current.focus();
        }
        return () => {
            // se estiver saindo da tab de search, remove os usuários selecionados
            if (tab === "search" || tab === "open") {
                setSearchParamValited(false);
                setSearchParam("");
                setSelectedUsers([]);
            }
        };
    }, [tab]);

    useEffect(() => {
        setTabOpen("open");
    }, [showAllTickets]);

    let searchTimeout;

    const handleSearch = (e) => {
        const searchedTerm = e.target.value;
        /* setSearchParam(searchedTerm); */
        clearTimeout(searchTimeout);
        if (searchedTerm === "") {
            setSearchParamValited(false);
            setSearchParam(searchedTerm);
            /*  setTab("open"); */
            return;
        }

        let vefivicationOfsearchedTerm = searchedTerm.trim();

        if (vefivicationOfsearchedTerm === "") {
            setSearchParamValited(true);
            return;
        } else if (vefivicationOfsearchedTerm.length >= 3) {
            setSearchParamValited(false);
            searchTimeout = setTimeout(() => {
                setSearchParam(searchedTerm);
            }, 500);
        } else {
            setSearchParamValited(true);
            return;
        }
    };

    const loadUsers = async () => {
        try {
            const { data } = await api.get(`/users/list`);
            const userList = data.map((u) => ({ id: u.id, name: u.name }));
            setUsers(userList);
        } catch (err) {
            toastError(err);
        }
    };

    const handleChangeTab = (e, newValue) => {
        setTab(newValue);
    };

    const handleChangeTabOpen = (e, newValue) => {
        setTabOpen(newValue);
    };

    const applyPanelStyle = (status) => {
        if (tabOpen !== status) {
            return { width: 0, height: 0 };
        }
    };

    const handleCloseOrOpenTicket = (ticket) => {
        setNewTicketModalOpen(false);
        if (ticket !== undefined && ticket.uuid !== undefined) {
            history.push(`/tickets/${ticket.uuid}`);
        }
    };

    const handleSelectedTags = (selecteds) => {
        const tags = selecteds.map((t) => t.id);
        setSelectedTags(tags);
    };

    const handleSelectedUsers = (selecteds) => {
        const users = selecteds.map((t) => t.id);
        setSelectedUsers(users);
    };

    const viewErrorMessage = (searchParamValited) => {
        if (searchParamValited === true) {
            return (
                <Typography className={classes.messageOfNotValidate}>
                    Quantidade de caracteres insuficiente.Por favor, digite no mínimo três caracteres.
                </Typography>
            );
        } else {
            return;
        }
    };

    return (
        <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                onClose={(ticket) => {
                    handleCloseOrOpenTicket(ticket);
                }}
            />
            <Paper elevation={0} square className={classes.tabsHeader}>
                <Tabs
                    value={tab}
                    onChange={handleChangeTab}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example">
                    <Tab value={"open"} icon={<MoveToInboxIcon />} label={i18n.t("tickets.tabs.open.title")} classes={{ root: classes.tab }} />
                    <Tab value={"closed"} icon={<CheckBoxIcon />} label={i18n.t("tickets.tabs.closed.title")} classes={{ root: classes.tab }} />
                    <Tab value={"search"} icon={<SearchIcon />} label={i18n.t("tickets.tabs.search.title")} classes={{ root: classes.tab }} />
                </Tabs>
            </Paper>
            <Paper square elevation={0} className={classes.ticketOptionsBox}>
                {tab === "search" ? (
                    <>
                        <div className={classes.serachInputWrapper}>
                            <SearchIcon className={classes.searchIcon} />
                            <InputBase
                                className={classes.searchInput}
                                inputRef={searchInputRef}
                                placeholder={i18n.t("tickets.search.searchContacts")}
                                type="search"
                                onChange={handleSearch}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <Button variant="outlined" color="primary" onClick={() => setNewTicketModalOpen(true)}>
                            <Add />
                        </Button>
                        <Can
                            role={user.profile}
                            perform="tickets-manager:showall"
                            yes={() => (
                                <FormControlLabel
                                    label={i18n.t("tickets.buttons.showAll")}
                                    labelPlacement="start"
                                    className={classes.showAllSwitch}
                                    control={
                                        <Switch
                                            size="small"
                                            checked={showAllTickets}
                                            onChange={() => setShowAllTickets((prevState) => !prevState)}
                                            name="showAllTickets"
                                            color="primary"
                                            className={classes.switchKey}
                                        />
                                    }
                                />
                            )}
                        />
                    </>
                )}
                <div style={{ flexDirection: "row", display: "flex", gap: 8 }}>
                    <TicketsQueueSelect
                        style={{ marginLeft: 6 }}
                        selectedQueueIds={selectedQueueIds}
                        userQueues={user?.queues}
                        onChange={(values) => setSelectedQueueIds(values)}
                    />

                    {profile === "admin" && (
                        <UserDropdownSelect userList={users} selectedUsers={selectedUsers} onChange={(values) => setSelectedUsers(values)} />
                    )}
                </div>
            </Paper>
            <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
                <Tabs value={tabOpen} onChange={handleChangeTabOpen} indicatorColor="primary" textColor="primary" variant="fullWidth">
                    <Tab
                        className={classes.tabs}
                        label={
                            <Badge className={classes.badge} badgeContent={openCount} color="primary">
                                {i18n.t("ticketsList.assignedHeader")}
                            </Badge>
                        }
                        value={"open"}
                    />
                    <Tab
                        className={classes.tabs}
                        label={
                            <Badge className={classes.badge} badgeContent={pendingCount} color="secondary">
                                {i18n.t("ticketsList.pendingHeader")}
                            </Badge>
                        }
                        value={"pending"}
                    />
                    {showCampaigns && (
                        <Tab
                            className={classes.tabs}
                            label={
                                <Badge className={classes.badge} badgeContent={campaignCount} color="secondary">
                                    {i18n.t("ticketsList.campaignHeader")}
                                </Badge>
                            }
                            value={"campaign"}
                        />
                    )}
                </Tabs>
                <Paper className={classes.ticketsWrapper}>
                    <TicketsListCustom
                        status="open"
                        showAll={showAllTickets}
                        selectedQueueIds={selectedQueueIds}
                        users={selectedUsers}
                        updateCount={(val) => setOpenCount(val)}
                        style={applyPanelStyle("open")}
                    />
                    <TicketsListCustom
                        status="pending"
                        showAll={showAllTickets}
                        selectedQueueIds={selectedQueueIds}
                        users={selectedUsers}
                        updateCount={(val) => setPendingCount(val)}
                        style={applyPanelStyle("pending")}
                    />
                    {showCampaigns && (
                        <TicketsListCustom
                            status="campaign"
                            selectedQueueIds={selectedQueueIds}
                            users={selectedUsers}
                            updateCount={(val) => setCampaignCount(val)}
                            style={applyPanelStyle("campaign")}
                        />
                    )}
                </Paper>
            </TabPanel>
            <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
                <TicketsListCustom status="closed" showAll={true} selectedQueueIds={selectedQueueIds} />
            </TabPanel>
            <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
                {viewErrorMessage(searchParamValited)}
                <TagsFilter onFiltered={handleSelectedTags} />
                {/* {profile === "admin" && (
          <UsersFilter onFiltered={handleSelectedUsers} />
        )} */}
                <TicketsListCustom
                    searchParam={searchParam}
                    showAll={true}
                    tags={selectedTags}
                    users={selectedUsers}
                    selectedQueueIds={selectedQueueIds}
                />
            </TabPanel>
        </Paper>
    );
};

export default TicketsManagerTabs;
