import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";

const App = () => {
    const [locale, setLocale] = useState();

    let theme = createTheme(
        {
            palette: {
                primary: { main: "#40b649" },
                danger: { main: "#525252" }
            }
        },
        locale
    );

    theme = createTheme(theme, {
        scrollbarStyles: {
            transition: "all 0.5s ease",
            scrollbarWidth: "thin",
            scrollbarColor: `${theme.palette.primary.main} #ffffff`,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
                display: "none"
            },
            ...theme.scrollbarStyles
        }
    });

    useEffect(() => {
        const i18nlocale = localStorage.getItem("i18nextLng");
        const browserLocale = i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

        if (browserLocale === "ptBR") {
            setLocale(ptBR);
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Routes />
        </ThemeProvider>
    );
};

export default App;
