import React, { useState, useContext } from "react";
// import { Link as RouterLink } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
// import Link from "@material-ui/core/Link";
// import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";
import logo from "../../assets/LOGO_ZAVIX_LOGIN.svg";
import bgLogin from "../../assets/Zavix_bg.png";

// const Copyright = () => {
// 	return (
// 		<Typography variant="body2" color="textSecondary" align="center">
// 			{"Copyleft "}
// 			<Link color="inherit" href="https://github.com/canove">
// 				Canove
// 			</Link>{" "}
// 			{new Date().getFullYear()}
// 			{"."}
// 		</Typography>
// 	);
// };

const useStyles = makeStyles((theme) => ({
    bgLogin: {
        display: "flex",
        width: "100vw",
        height: "100vh",
        position: "relative",
        backgroundImage: `url(${bgLogin})`, // Define a imagem de fundo aqui
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundBlendMode: "multiply",
        backgroundAttachment: "fixed"
    },
    paper: {
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        height: "70vh",
        width: "85vw",
        backgroundColor: "#fff",
        borderRadius: 20,
        padding: "16px 32px",
        [theme.breakpoints.up("sm")]: {
            height: "600px",
            width: "500px"
        }
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    title: {
        color: "#777777"
    }
}));

const Login = () => {
    const classes = useStyles();

    const [user, setUser] = useState({ email: "", password: "" });

    const { handleLogin } = useContext(AuthContext);

    const handleChangeInput = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handlSubmit = (e) => {
        e.preventDefault();
        handleLogin(user);
    };

    return (
        <div className={classes.bgLogin}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <div>
                        <center>
                            <img style={{ margin: "0 auto", width: "65%", marginBottom: 50 }} src={logo} alt="logologin" />
                        </center>
                    </div>
                    <div>
                        <Typography component="h1" variant="h5" className={classes.title}>
                            {i18n.t("login.title")}
                        </Typography>
                        <form className={classes.form} noValidate onSubmit={handlSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={i18n.t("login.form.email")}
                                name="email"
                                value={user.email}
                                onChange={handleChangeInput}
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={i18n.t("login.form.password")}
                                type="password"
                                id="password"
                                value={user.password}
                                onChange={handleChangeInput}
                                autoComplete="current-password"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{ color: "#FFFFFF" }}
                                className={classes.submit}>
                                {i18n.t("login.buttons.submit")}
                            </Button>
                            {/*<Grid container>
                            <Grid item>
                                <Link
                                    href="#"
                                    variant="body2"
                                    component={RouterLink}
                                    to="/signup"
                                >
                                    {i18n.t("login.buttons.register")}
                                </Link>
                            </Grid>
                        </Grid>*/}
                        </form>
                    </div>
                </div>
                <Box mt={8}>{/* <Copyright /> */}</Box>
            </Container>
        </div>
    );
};

export default Login;
